import { SVGProps } from 'react';

export interface IconSoundWaveProps extends SVGProps<SVGSVGElement> {
	className?: string;
	testId?: string;
}

const IconSoundWave = (props: IconSoundWaveProps) => {
	const { testId = 'IconSoundWave', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 40 40"
			fill="none"
			aria-label="Sound Wave Icon"
			{...remainingProps}
		>
			<path
				d="M35 26.875C34.31 26.875 33.75 26.315 33.75 25.625V14.375C33.75 13.685 34.31 13.125 35 13.125C35.69 13.125 36.25 13.685 36.25 14.375V25.625C36.25 26.315 35.69 26.875 35 26.875Z"
				fill="black"
			/>
			<path
				d="M30 34.375C29.31 34.375 28.75 33.815 28.75 33.125V6.875C28.75 6.185 29.31 5.625 30 5.625C30.69 5.625 31.25 6.185 31.25 6.875V33.125C31.25 33.815 30.69 34.375 30 34.375Z"
				fill="black"
			/>
			<path
				d="M25 30C24.31 30 23.75 29.44 23.75 28.75V11.25C23.75 10.56 24.31 10 25 10C25.69 10 26.25 10.56 26.25 11.25V28.75C26.25 29.44 25.69 30 25 30Z"
				fill="black"
			/>
			<path
				d="M20 23.125C19.31 23.125 18.75 22.565 18.75 21.875V18.125C18.75 17.435 19.31 16.875 20 16.875C20.69 16.875 21.25 17.435 21.25 18.125V21.875C21.25 22.565 20.69 23.125 20 23.125Z"
				fill="black"
			/>
			<path
				d="M15 30C14.31 30 13.75 29.44 13.75 28.75V11.25C13.75 10.56 14.31 10 15 10C15.69 10 16.25 10.56 16.25 11.25V28.75C16.25 29.44 15.69 30 15 30Z"
				fill="black"
			/>
			<path
				d="M10 34.375C9.31 34.375 8.75 33.815 8.75 33.125V6.875C8.75 6.185 9.31 5.625 10 5.625C10.69 5.625 11.25 6.185 11.25 6.875V33.125C11.25 33.815 10.69 34.375 10 34.375Z"
				fill="black"
			/>
			<path
				d="M5 26.875C4.31 26.875 3.75 26.315 3.75 25.625V14.375C3.75 13.685 4.31 13.125 5 13.125C5.69 13.125 6.25 13.685 6.25 14.375V25.625C6.25 26.315 5.69 26.875 5 26.875Z"
				fill="black"
			/>
		</svg>
	);
};

export default IconSoundWave;
