import { SVGProps } from 'react';
import cn from 'classnames';

export interface IconCloseProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconClose = (props: IconCloseProps) => {
	const { testId = 'IconClose', className, ...remainingProps } = props;

	return (
		<svg
			aria-label="close icon"
			data-testid={testId}
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			className={cn('m-auto h-6 w-6', className)}
			{...remainingProps}
		>
			<path
				className="fill-black"
				fillRule="evenodd"
				d="M19.186 19.186a1.071 1.071 0 0 1-1.515 0L4.814 6.33a1.071 1.071 0 0 1 1.515-1.515L19.186 17.67a1.071 1.071 0 0 1 0 1.515Z"
				clipRule="evenodd"
			/>
			<path
				className="fill-black"
				fillRule="evenodd"
				d="M19.186 4.814a1.071 1.071 0 0 1 0 1.515L6.33 19.186a1.071 1.071 0 0 1-1.515-1.515L17.67 4.814a1.071 1.071 0 0 1 1.515 0Z"
				clipRule="evenodd"
			/>
		</svg>
	);
};

export default IconClose;
