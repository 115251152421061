import { SVGProps } from 'react';

export interface IconBrokenFileProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconBrokenFile = (props: IconBrokenFileProps) => {
	const { testId = 'IconBrokenFile', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			className={className}
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-label="Broken File Icon"
			{...remainingProps}
		>
			<path d="M8.75008 4.375C7.3002 4.375 6.12508 5.55013 6.12508 7V15.3868C6.4112 15.3427 6.7017 15.3125 7.00008 15.3125C7.29845 15.3125 7.58895 15.3427 7.87508 15.3868V7C7.87508 6.51656 8.26708 6.125 8.75008 6.125H13.1251V10.5C13.1251 11.9499 14.3002 13.125 15.7501 13.125H20.1251V21C20.1251 21.4834 19.7331 21.875 19.2501 21.875H12.6132C12.5165 22.4971 12.3225 23.0864 12.0407 23.625H19.2501C20.7 23.625 21.8751 22.4499 21.8751 21V12.5371C21.8751 11.8406 21.5982 11.1733 21.106 10.6812L15.5689 5.14404C15.0763 4.65186 14.409 4.375 13.713 4.375H8.75008ZM14.8751 6.9248L19.3253 11.375H15.7501C15.2666 11.375 14.8751 10.9834 14.8751 10.5V6.9248ZM7.00008 16.625C5.88041 16.625 4.76103 17.0525 3.90682 17.9067C2.19838 19.6152 2.19838 22.3848 3.90682 24.0933C5.61525 25.8017 8.3849 25.8017 10.0933 24.0933C11.8018 22.3848 11.8018 19.6152 10.0933 17.9067C9.23912 17.0525 8.11975 16.625 7.00008 16.625ZM5.45345 19.0159C5.56539 19.0158 5.67724 19.0585 5.76277 19.144L7.00008 20.3813L8.23738 19.144C8.40801 18.9734 8.68497 18.973 8.85604 19.144C9.0271 19.3151 9.02666 19.5921 8.85604 19.7627L7.61873 21L8.85604 22.2373C9.0271 22.4084 9.02666 22.6853 8.85604 22.856C8.68541 23.0266 8.40845 23.027 8.23738 22.856L7.00008 21.6187L5.76277 22.856C5.59215 23.0266 5.31518 23.027 5.14412 22.856C4.97306 22.6849 4.9735 22.4079 5.14412 22.2373L6.38143 21L5.14412 19.7627C4.97306 19.5916 4.9735 19.3147 5.14412 19.144C5.22943 19.0587 5.3415 19.0159 5.45345 19.0159Z" />
		</svg>
	);
};

export default IconBrokenFile;
