export interface IconAnalyzeGradientProps {
	testId?: string;
}

const IconAnalyzeGradient = (props: IconAnalyzeGradientProps) => {
	const { testId = 'IconAnalyzeGradient' } = props;

	return (
		<svg
			data-testid={testId}
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 36 36"
			aria-label="Search Icon"
		>
			<path
				fill="url(#icon_analyze_gradient)"
				d="M4.154 2.077A4.157 4.157 0 0 0 0 6.23v18a4.157 4.157 0 0 0 4.154 4.154h15.874l.728-.728a8.774 8.774 0 0 1-.568-2.042H4.154a1.389 1.389 0 0 1-1.385-1.384V9.692c0-.761.623-1.384 1.385-1.384h22.154c.761 0 1.384.623 1.384 1.384v5.643a9.175 9.175 0 0 1 2.77 0V6.23a4.157 4.157 0 0 0-4.154-4.154H4.154Zm4.5 8.654a1.384 1.384 0 1 0 0 2.768 1.384 1.384 0 0 0 0-2.768Zm4.154.346a1.038 1.038 0 1 0 0 2.077h9a1.038 1.038 0 1 0 0-2.077h-9Zm-4.154 4.5a1.384 1.384 0 1 0 0 2.768 1.384 1.384 0 0 0 0-2.768Zm4.154.346a1.038 1.038 0 1 0 0 2.077h9a1.038 1.038 0 1 0 0-2.077h-9Zm16.269 1.385a6.93 6.93 0 0 0-6.923 6.923c0 1.409.424 2.72 1.15 3.814L19.79 31.56a1.384 1.384 0 1 0 1.958 1.958l3.514-3.514a6.876 6.876 0 0 0 3.815 1.15A6.931 6.931 0 0 0 36 24.232a6.93 6.93 0 0 0-6.923-6.923Zm0 2.769a4.158 4.158 0 0 1 4.154 4.154 4.158 4.158 0 0 1-4.154 4.154 4.158 4.158 0 0 1-4.154-4.154 4.158 4.158 0 0 1 4.154-4.154Zm-20.423.346a1.384 1.384 0 1 0 0 2.769 1.384 1.384 0 0 0 0-2.769Zm4.154.346c-.575 0-1.039.464-1.039 1.039 0 .574.464 1.038 1.039 1.038h7.373a9.16 9.16 0 0 1 .588-2.077h-7.961Z"
			/>
			<defs>
				<linearGradient
					id="icon_analyze_gradient"
					x1="10.93"
					x2="61.525"
					y1="-28.442"
					y2="-.051"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset=".5" stopColor="#EC4899" />
					<stop offset="1" stopColor="#0EA5E9" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default IconAnalyzeGradient;
