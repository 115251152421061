import { SVGProps } from 'react';

export interface IconHVACUnitProps extends SVGProps<SVGSVGElement> {
	className?: string;
}

const IconHVACUnit = (props: IconHVACUnitProps) => {
	const { className, ...remainingProps } = props;

	return (
		<svg
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-label="paper file icon"
			role="graphics-symbol"
			className={className}
			{...remainingProps}
		>
			<g id="air conditioner">
				<g id="Vector">
					<path
						d="M35.3333 9.00016L28.4166 4.00016C27.8333 3.5835 27.0833 3.3335 26.4166 3.3335H13.5833C12.9166 3.3335 12.1666 3.5835 11.5833 4.00016L4.66659 9.00016C3.83325 9.66683 3.33325 10.6668 3.33325 11.6668V30.8335C3.33325 32.6668 4.83325 34.1668 6.66659 34.1668H7.49992L9.16659 36.6668H11.6666L13.3333 34.1668H26.6666L28.3333 36.6668H30.8333L32.4999 34.1668H33.3333C35.1666 34.1668 36.6666 32.6668 36.6666 30.8335V11.6668C36.6666 10.6668 36.1666 9.66683 35.3333 9.00016ZM34.1666 30.8335C34.1666 31.3335 33.8333 31.6668 33.3333 31.6668H6.66659C6.16659 31.6668 5.83325 31.3335 5.83325 30.8335V11.6668C5.83325 11.4168 5.99992 11.1668 6.16659 11.0002L13.0833 6.00016C13.2499 5.91683 13.4166 5.8335 13.5833 5.8335H26.4166C26.5833 5.8335 26.7499 5.91683 26.9166 6.00016L33.8333 11.0002C34.0833 11.1668 34.1666 11.4168 34.1666 11.6668V30.8335Z"
						fill="black"
					/>
					<path
						d="M17.9166 14.1668H8.74992C8.08325 14.1668 7.49992 14.7502 7.49992 15.4168C7.49992 16.0835 8.08325 16.6668 8.74992 16.6668H17.9166C18.5833 16.6668 19.1666 16.0835 19.1666 15.4168C19.1666 14.7502 18.5833 14.1668 17.9166 14.1668Z"
						fill="black"
					/>
					<path
						d="M17.9166 18.3335H8.74992C8.08325 18.3335 7.49992 18.9168 7.49992 19.5835C7.49992 20.2502 8.08325 20.8335 8.74992 20.8335H17.9166C18.5833 20.8335 19.1666 20.2502 19.1666 19.5835C19.1666 18.9168 18.5833 18.3335 17.9166 18.3335Z"
						fill="black"
					/>
					<path
						d="M17.9166 22.5002H8.74992C8.08325 22.5002 7.49992 23.0835 7.49992 23.7502C7.49992 24.4168 8.08325 25.0002 8.74992 25.0002H17.9166C18.5833 25.0002 19.1666 24.4168 19.1666 23.7502C19.1666 23.0835 18.5833 22.5002 17.9166 22.5002Z"
						fill="black"
					/>
					<path
						d="M17.9166 26.6668H8.74992C8.08325 26.6668 7.49992 27.2502 7.49992 27.9168C7.49992 28.5835 8.08325 29.1668 8.74992 29.1668H17.9166C18.5833 29.1668 19.1666 28.5835 19.1666 27.9168C19.1666 27.2502 18.5833 26.6668 17.9166 26.6668Z"
						fill="black"
					/>
					<path
						d="M31.2499 14.1668H22.0833C21.4166 14.1668 20.8333 14.7502 20.8333 15.4168C20.8333 16.0835 21.4166 16.6668 22.0833 16.6668H31.2499C31.9166 16.6668 32.4999 16.0835 32.4999 15.4168C32.4999 14.7502 31.9166 14.1668 31.2499 14.1668Z"
						fill="black"
					/>
					<path
						d="M31.2499 18.3335H22.0833C21.4166 18.3335 20.8333 18.9168 20.8333 19.5835C20.8333 20.2502 21.4166 20.8335 22.0833 20.8335H31.2499C31.9166 20.8335 32.4999 20.2502 32.4999 19.5835C32.4999 18.9168 31.9166 18.3335 31.2499 18.3335Z"
						fill="black"
					/>
					<path
						d="M31.2499 22.5002H22.0833C21.4166 22.5002 20.8333 23.0835 20.8333 23.7502C20.8333 24.4168 21.4166 25.0002 22.0833 25.0002H31.2499C31.9166 25.0002 32.4999 24.4168 32.4999 23.7502C32.4999 23.0835 31.9166 22.5002 31.2499 22.5002Z"
						fill="black"
					/>
					<path
						d="M31.2499 26.6668H22.0833C21.4166 26.6668 20.8333 27.2502 20.8333 27.9168C20.8333 28.5835 21.4166 29.1668 22.0833 29.1668H31.2499C31.9166 29.1668 32.4999 28.5835 32.4999 27.9168C32.4999 27.2502 31.9166 26.6668 31.2499 26.6668Z"
						fill="black"
					/>
					<path
						d="M19.9999 10.3335C24.1421 10.3335 27.4999 9.77385 27.4999 9.0835C27.4999 8.39314 24.1421 7.8335 19.9999 7.8335C15.8578 7.8335 12.4999 8.39314 12.4999 9.0835C12.4999 9.77385 15.8578 10.3335 19.9999 10.3335Z"
						fill="black"
					/>
				</g>
			</g>
		</svg>
	);
};

export default IconHVACUnit;
