import classNames from 'classnames';
import { SVGProps } from 'react';

export interface IconTwoGradientProps extends SVGProps<SVGSVGElement> {
	className?: string;
	testId?: string;
}

const IconTwoGradient = (props: IconTwoGradientProps) => {
	const { className, testId = 'IconTwoGradient', ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 37 36"
			fill="none"
			className={classNames(className)}
			aria-label="step number 2"
			{...remainingProps}
		>
			<path
				d="M18.5 0C8.55909 0 0.5 8.05909 0.5 18C0.5 27.9409 8.55909 36 18.5 36C28.4409 36 36.5 27.9409 36.5 18C36.5 8.05909 28.4409 0 18.5 0ZM18.5 3.27273C26.6335 3.27273 33.2273 9.86645 33.2273 18C33.2273 26.1335 26.6335 32.7273 18.5 32.7273C10.3665 32.7273 3.77273 26.1335 3.77273 18C3.77273 9.86645 10.3665 3.27273 18.5 3.27273ZM18.377 11.5185C15.978 11.5185 14.3856 12.8286 13.9553 14.2351C13.8939 14.4192 13.8594 14.6035 13.8594 14.8056C13.8594 15.4642 14.2821 15.8874 14.994 15.8874C15.5651 15.8874 15.8715 15.6417 16.1701 15.114C16.6275 14.0945 17.3303 13.5847 18.3498 13.5847C19.4838 13.5847 20.293 14.332 20.293 15.3777C20.293 16.2916 19.8886 16.9061 18.3242 18.4091L14.5449 22.0206C14.0082 22.5123 13.8146 22.8478 13.8146 23.3485C13.8146 24.0162 14.2289 24.4815 15.0643 24.4831H22.0859C22.7716 24.4831 23.1838 24.0527 23.1838 23.4284C23.1838 22.787 22.7716 22.3738 22.0859 22.3738H17.3031V22.321L20.0469 19.6843C22.121 17.7239 22.877 16.7587 22.877 15.2115C22.877 13.0318 21.0663 11.5185 18.377 11.5185Z"
				fill="url(#icon_two_gradient)"
			/>
			<defs>
				<linearGradient
					id="icon_two_gradient"
					x1="11.43"
					y1="-34.5"
					x2="64.8045"
					y2="-8.00469"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset="0.5" stopColor="#EC4899" />
					<stop offset="1" stopColor="#0EA5E9" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default IconTwoGradient;
