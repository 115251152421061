import { SVGProps } from 'react';

export interface IconOtherFileProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconOtherFile = (props: IconOtherFileProps) => {
	const { testId = 'IconOtherFile', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			className={className}
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-label="Other File Icon"
			{...remainingProps}
		>
			<path
				d="M8.75 4.375C7.30013 4.375 6.125 5.55013 6.125 7V21C6.125 22.4499 7.30013 23.625 8.75 23.625H19.25C20.6999 23.625 21.875 22.4499 21.875 21V12.5371C21.875 11.8406 21.5981 11.1733 21.106 10.6812L15.5688 5.14404C15.0762 4.65186 14.409 4.375 13.7129 4.375H8.75ZM8.75 6.125H13.125V10.5C13.125 11.9499 14.3001 13.125 15.75 13.125H20.125V21C20.125 21.4834 19.7334 21.875 19.25 21.875H8.75C8.26656 21.875 7.875 21.4834 7.875 21V7C7.875 6.51656 8.26656 6.125 8.75 6.125ZM14.875 6.9248L19.3252 11.375H15.75C15.2666 11.375 14.875 10.9834 14.875 10.5V6.9248Z"
				fill="black"
			/>
		</svg>
	);
};

export default IconOtherFile;
