import { SVGProps } from 'react';

export interface IconRulerGradientProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconRulerGradient = (props: IconRulerGradientProps) => {
	const { testId = 'IconRulerGradient' } = props;

	return (
		<svg
			data-testid={testId}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 37 36"
			aria-label="Ruler Icon"
			{...props}
		>
			<path
				fill="url(#IconRuler_a)"
				d="M23.75.182c-1.002 0-1.943.39-2.651 1.098L1.78 20.6a3.754 3.754 0 0 0 0 5.302L10.6 34.72a3.724 3.724 0 0 0 2.651 1.098c1.002 0 1.944-.39 2.652-1.098L35.22 15.4a3.754 3.754 0 0 0 0-5.302L26.4 1.28a3.724 3.724 0 0 0-2.65-1.098Zm0 3a.75.75 0 0 1 .53.22L33.1 12.22a.75.75 0 0 1 0 1.06L13.78 32.6a.75.75 0 0 1-1.06 0L3.9 23.78a.75.75 0 0 1 0-1.06l1.85-1.849 2.689 2.69c.293.293.676.439 1.06.439s.767-.146 1.06-.44a1.5 1.5 0 0 0 0-2.12l-2.689-2.69 2.38-2.379 4.939 4.94c.293.293.676.439 1.06.439s.767-.146 1.06-.44a1.5 1.5 0 0 0 0-2.12l-4.939-4.94 2.38-2.379 2.689 2.69c.293.293.676.439 1.06.439s.767-.146 1.06-.44a1.5 1.5 0 0 0 0-2.12l-2.689-2.69 2.38-2.379 4.939 4.94c.293.293.676.439 1.06.439s.767-.146 1.06-.44a1.5 1.5 0 0 0 0-2.12l-4.939-4.94 1.849-1.849a.75.75 0 0 1 .53-.22Z"
			/>
			<defs>
				<linearGradient
					id="IconRuler_a"
					x1={11.502}
					x2={64.334}
					y1={-33.97}
					y2={-7.748}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset={0.5} stopColor="#EC4899" />
					<stop offset={1} stopColor="#0EA5E9" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default IconRulerGradient;
