import { SVGProps } from 'react';

export interface IconStarOliveBranchesProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconStarOliveBranches = (props: IconStarOliveBranchesProps) => {
	const { testId = 'IconStarOliveBranches', ...svgProps } = props;

	if (!svgProps.className?.includes('fill'))
		console.warn('IconStarOliveBranches: fill class missing');

	return (
		<svg
			data-testid={testId}
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 56 56"
			aria-label="Star flanked by olive branches"
			{...svgProps}
		>
			<path d="M33.653 37.623c-.298 0-.595-.08-.85-.245L28 34.394l-4.804 2.984a1.617 1.617 0 0 1-1.776-.044 1.604 1.604 0 0 1-.656-1.654l1.181-5.705-4.296-3.736a1.61 1.61 0 0 1-.481-1.706 1.622 1.622 0 0 1 1.373-1.12l5.697-.587 2.266-5.24c.254-.596.84-.972 1.479-.972.638 0 1.225.385 1.478.971l2.267 5.241 5.696.587a1.61 1.61 0 0 1 1.374 1.12c.192.612 0 1.286-.482 1.706l-4.296 3.736 1.181 5.705a1.625 1.625 0 0 1-.656 1.654c-.28.192-.604.289-.927.289h.035ZM13.851 43.503c-1.61-1.523-2.213-3.658-1.785-5.425.412-1.794 1.733-3.142 3.5-3.947.604 1.313.7 2.8.455 4.48-.28 1.663-.84 3.465-2.17 4.9v-.008ZM9.021 36.643c-.77-1.777-.577-3.693.342-5.014.892-1.339 2.397-2.074 4.068-2.196.105 1.356-.262 2.65-1.023 3.963a8.3 8.3 0 0 1-1.4 1.847c-.552.56-1.208 1.058-1.987 1.4ZM7.263 28.444c0-1.654.743-3.115 1.898-3.903 1.103-.813 2.564-.97 3.947-.568-.193.586-.412 1.137-.797 1.627a5.948 5.948 0 0 1-1.295 1.365c-.98.814-2.327 1.453-3.753 1.479ZM8.846 20.21c.543-1.269 1.575-2.152 2.704-2.432a3.645 3.645 0 0 1 1.68-.044c.56.122 1.094.376 1.584.735-.341.429-.683.814-1.146 1.094-.42.306-.91.542-1.453.7-1.024.34-2.249.402-3.369-.053ZM12.854 13.971c.752-.805 1.767-1.198 2.704-1.137.918.026 1.776.542 2.345 1.312a4.59 4.59 0 0 1-1.19.595 5.161 5.161 0 0 1-1.313.184c-.892-.017-1.837-.306-2.537-.954h-.01Z" />
			<path d="M13.851 43.503a15.486 15.486 0 0 0-5.285-3.29c-1.89-.683-3.946-.963-5.687-.167 1.234 2.687 3.491 4.454 5.6 4.97 1.059.272 2.074.254 3.001-.026.919-.271 1.706-.779 2.371-1.487ZM9.021 36.643c-.752-1.76-1.88-3.22-3.193-4.384-1.322-1.138-2.914-1.978-4.612-1.846.193 2.493 1.453 4.585 2.95 5.678.787.543 1.6.875 2.423.963.84.087 1.654-.061 2.432-.403v-.008ZM7.263 28.444c.008-1.636-.438-3.168-1.12-4.489C5.408 22.643 4.41 21.531 3 21.12c-.306.989-.323 1.995-.236 2.905.131.91.429 1.733.849 2.398.892 1.347 2.187 2.038 3.649 2.012v.009ZM8.846 20.21a7.335 7.335 0 0 0 .534-3.797 5.48 5.48 0 0 0-.525-1.75c-.262-.525-.621-.998-1.111-1.357-.508.657-.805 1.418-1.006 2.118a5.585 5.585 0 0 0-.07 2.074c.27 1.295 1.05 2.248 2.17 2.712h.008ZM12.854 13.971a5.506 5.506 0 0 0 1.4-2.72c.157-.99.079-1.952-.508-2.748a4.43 4.43 0 0 0-1.233 1.33 3.55 3.55 0 0 0-.56 1.522c-.14 1.015.183 1.951.9 2.608v.008Z" />
			<path d="M17.728 10.655c-6.195 3.876-9.88 11.183-9.328 18.253.359 7.078 4.874 13.606 11.104 16.467.892.429 1.4 1.426 1.094 2.363-.298.91-1.462 1.33-2.564.822 0 0-.166-.096-.481-.262-.315-.167-.77-.43-1.313-.805-1.111-.71-2.625-1.873-4.165-3.535-1.531-1.654-3.071-3.824-4.147-6.414-.578-1.278-.972-2.669-1.304-4.095-.271-1.435-.481-2.905-.473-4.384-.043-1.479.132-2.949.377-4.366.306-1.409.656-2.791 1.198-4.051a21.169 21.169 0 0 1 3.894-6.318 20.736 20.736 0 0 1 3.911-3.465c.517-.367.937-.63 1.234-.796a9.67 9.67 0 0 1 .455-.263c.621-.358 1.103.464.516.858l-.008-.009Z" />
			<path d="M15.82 11.329c.21-1.024.936-1.899 1.873-2.415.936-.525 2.082-.63 3.045-.289-.98 1.829-2.862 2.853-4.918 2.704ZM42.298 43.783a16.537 16.537 0 0 1 5.416-3.273c1.916-.674 3.99-.936 5.722-.114-1.303 2.713-3.63 4.48-5.766 4.98a7.128 7.128 0 0 1-1.566.183 5.61 5.61 0 0 1-1.461-.245c-.92-.289-1.698-.814-2.345-1.531ZM47.346 36.896c.797-1.776 1.97-3.255 3.282-4.436 1.356-1.146 2.93-1.986 4.637-1.846-.201 2.537-1.514 4.655-3.045 5.757-1.566 1.12-3.342 1.243-4.874.525ZM49.236 28.566c.018-1.67.455-3.22 1.155-4.558.735-1.33 1.75-2.46 3.142-2.853.315 1.006.315 2.039.236 2.958-.158.927-.42 1.758-.866 2.432-.884 1.365-2.205 2.056-3.667 2.021ZM47.67 20.166a7.501 7.501 0 0 1-.569-3.858c.15-1.243.648-2.424 1.61-3.133.508.674.832 1.435 1.033 2.161.157.744.201 1.462.087 2.11-.262 1.312-1.032 2.274-2.152 2.73l-.009-.01ZM43.566 13.823a5.724 5.724 0 0 1-1.452-2.748c-.184-.997-.114-1.969.455-2.765.542.368.954.849 1.277 1.339.324.481.517 1.015.587 1.531.166 1.024-.15 1.969-.867 2.634v.009Z" />
			<path d="M42.298 43.783c1.67-1.514 2.353-3.667 1.96-5.47-.368-1.82-1.654-3.202-3.404-4.033-.639 1.33-.779 2.835-.586 4.533.052.42.13.848.236 1.286.096.42.219.849.376 1.277.324.85.779 1.69 1.426 2.407h-.008ZM47.346 36.896c.805-1.793.665-3.745-.245-5.1-.41-.675-1.04-1.226-1.723-1.602-.692-.376-1.48-.595-2.32-.665-.104 1.382.22 2.704.963 4.042.718 1.313 1.794 2.617 3.325 3.325ZM49.236 28.566c.018-1.688-.726-3.185-1.872-3.998-1.111-.84-2.564-1.007-3.955-.613.201.595.411 1.164.796 1.663.315.516.788.98 1.278 1.408.49.42 1.076.797 1.715 1.068.63.271 1.303.455 2.03.472h.008ZM47.67 20.166c-.56-1.286-1.601-2.196-2.739-2.493-1.137-.307-2.3-.044-3.272.682.332.438.7.831 1.164 1.12.42.315.927.551 1.46.726 1.025.35 2.267.42 3.387-.035ZM43.566 13.823c-.77-.814-1.802-1.217-2.747-1.155-.928.026-1.794.542-2.345 1.32.394.255.787.473 1.216.596.438.114.884.184 1.33.184.91-.018 1.846-.298 2.546-.954v.009Z" />
			<path d="M39.078 9.623c3.42 1.855 6.326 4.69 8.303 8.102a21.651 21.651 0 0 1 2.958 11.393C50.33 37.158 45.404 45.06 38 48.726c-1.093.56-2.327.175-2.66-.717-.35-.91.15-1.943 1.05-2.398l.43-.2c.28-.123.69-.307 1.19-.605 1.014-.533 2.405-1.435 3.858-2.782 1.452-1.348 2.966-3.15 4.095-5.39 1.146-2.223 1.951-4.839 2.117-7.543.132-1.347.044-2.703-.087-4.042-.201-1.321-.455-2.625-.893-3.833a19.776 19.776 0 0 0-3.35-6.15 19.457 19.457 0 0 0-3.597-3.5 12.042 12.042 0 0 0-1.155-.823c-.28-.175-.429-.272-.429-.272-.577-.367-.166-1.233.499-.857l.009.009Z" />
			<path d="M40.521 11.154a5.306 5.306 0 0 1-5.04-2.695 3.976 3.976 0 0 1 1.55-.193c.533.035 1.05.228 1.54.464.962.516 1.714 1.391 1.95 2.424Z" />
		</svg>
	);
};

export default IconStarOliveBranches;
