import { SVGProps } from 'react';
import './icon-arrow-left.css';
import classNames from 'classnames';

export interface IconArrowLeftProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconArrowLeft = (props: IconArrowLeftProps) => {
	const { testId = 'IconArrowLeft', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			width="14"
			height="12"
			viewBox="0 0 14 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-label="Arrow Left"
			className={classNames('inline-block', className)}
			{...remainingProps}
		>
			<path d="M5.26623 0.700338C5.10627 0.702916 4.94784 0.766095 4.82799 0.890158L0.301016 5.56545C0.0663281 5.80764 0.0663281 6.1924 0.301016 6.43459L4.82799 11.1099C4.95049 11.2364 5.11348 11.3003 5.2766 11.3003C5.43316 11.3003 5.59022 11.2414 5.71178 11.1239C5.9596 10.8839 5.96613 10.4879 5.72582 10.2401L2.22545 6.62502H13.2502C13.5952 6.62502 13.8752 6.34502 13.8752 6.00002C13.8752 5.65502 13.5952 5.37502 13.2502 5.37502H2.22545L5.72582 1.75991C5.96582 1.5121 5.9596 1.11643 5.71178 0.876119C5.58772 0.756119 5.42619 0.69776 5.26623 0.700338Z" />
		</svg>
	);
};

export default IconArrowLeft;
