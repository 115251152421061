import { SVGProps } from 'react';

export interface IconHomeDollarGradientProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconHomeDollarGradient = (props: IconHomeDollarGradientProps) => {
	const { testId = 'IconHomeDollarGradient' } = props;

	return (
		<svg
			data-testid={testId}
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-label="Home Icon with dollar bill symbol"
			{...props}
		>
			<path
				d="M20 5C19.5 5 18.8334 5.16667 18.3334 5.5L5.50004 16.3333C5.00004 16.6667 4.83337 17.5 5.16671 18C5.50004 18.6667 6.50004 18.8333 7.00004 18.3333L7.50004 18V29.5C7.50004 31.1667 8.83337 32.6667 10.6667 32.6667H29.3334C31 32.6667 32.5 31.3333 32.5 29.5V18L33 18.3333C33.1667 18.5 33.5 18.6667 33.8334 18.6667C34.3334 18.6667 34.6667 18.5 35 18C35.3334 17.5 35.1667 16.8333 34.6667 16.3333L21.6667 5.5C21.1667 5.16667 20.5 5 20 5ZM20 7.66667H20.1667L30 15.8333V28.6667C30 29.3333 29.5 30 28.6667 30H11.3334C10.5 30 10 29.5 10 28.6667V15.8333L19.8334 7.66667H20ZM20 13.1667C19.6667 13.1667 19.5 13.3333 19.5 13.6667V14.6667C17.1667 14.8333 15.5 16.1667 15.5 18.1667C15.5 20 16.6667 21 19 21.5L19.6667 21.6667V24.8333C18.6667 24.6667 18 24.3333 17.8334 23.6667C17.6667 23.1667 17.1667 22.8333 16.6667 22.8333C16 22.8333 15.5 23.1667 15.5 23.8333C15.5 24 15.5 24.1667 15.6667 24.3333C16.1667 25.5 17.5 26.6667 19.6667 26.6667V27.6667C19.6667 28 19.8334 28.1667 20.1667 28.1667C20.5 28.1667 20.6667 28 20.6667 27.6667V26.6667C23.1667 26.5 24.8334 25.1667 24.8334 23C24.8334 21.1667 23.6667 20.1667 21.3334 19.6667L20.6667 19.5V16.5C21.5 16.6667 22 17 22.3334 17.6667C22.6667 18.1667 23 18.3333 23.5 18.3333C24.1667 18.3333 24.5 18 24.5 17.3333C24.5 17.1667 24.5 17 24.3334 16.8333C24 15.5 22.5 14.5 20.5 14.3333V13.5C20.3334 13.3333 20.1667 13.1667 20 13.1667ZM19.5 16.5V19.3333C18.3334 19 17.8334 18.5 17.8334 17.8333C17.8334 17.1667 18.3334 16.6667 19.5 16.5ZM20.3334 21.8333C21.6667 22.1667 22.1667 22.5 22.1667 23.3333C22.1667 24.1667 21.5 24.6667 20.3334 24.8333V21.8333Z"
				fill="url(#paint0_linear_4646_1861)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_4646_1861"
					x1="14.1564"
					y1="-21.5139"
					x2="57.2827"
					y2="1.8391"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset="0.5" stopColor="#EC4899" />
					<stop offset="1" stopColor="#0EA5E9" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default IconHomeDollarGradient;
