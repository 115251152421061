import classNames from 'classnames';
import { SVGProps } from 'react';

export interface IconOneGradientProps extends SVGProps<SVGSVGElement> {
	className?: string;
}

const IconOneGradient = (props: IconOneGradientProps) => {
	const { className, ...remainingProps } = props;

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 37 36"
			className={classNames(className)}
			aria-label="step number 1"
			{...remainingProps}
		>
			<path
				fill="url(#icon_one_gradient)"
				d="M18.5 0C8.56 0 .5 8.06.5 18s8.06 18 18 18 18-8.06 18-18-8.06-18-18-18Zm0 3.273c8.134 0 14.727 6.593 14.727 14.727S26.634 32.727 18.5 32.727c-8.133 0-14.727-6.593-14.727-14.727S10.367 3.273 18.5 3.273Zm.866 8.197c-.545 0-.968.079-1.584.5l-2.512 1.74c-.413.291-.572.59-.572.995 0 .562.396.95.932.95.272 0 .457-.063.703-.239l1.855-1.291h.053v9.078c0 .8.536 1.326 1.319 1.326.782 0 1.308-.526 1.308-1.326V12.955c0-.914-.562-1.485-1.502-1.485Z"
			/>
			<defs>
				<linearGradient
					id="icon_one_gradient"
					x1="11.43"
					x2="64.805"
					y1="-34.5"
					y2="-8.005"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset=".5" stopColor="#EC4899" />
					<stop offset="1" stopColor="#0EA5E9" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default IconOneGradient;
