import { SVGProps } from 'react';

export interface IconClipboardGradientProps extends SVGProps<SVGSVGElement> {
	testId?: string;
	className?: string;
}

const IconClipboardGradient = (props: IconClipboardGradientProps) => {
	const { testId = 'IconClipboardGradient', className } = props;

	return (
		<svg
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 49 48"
			data-testid={testId}
			className={className}
			aria-label="clipboard icon"
		>
			<path
				d="M15.5 7.5A4.5 4.5 0 0 0 11 12v24a4.5 4.5 0 0 0 4.5 4.5h18A4.5 4.5 0 0 0 38 36V12a4.5 4.5 0 0 0-4.5-4.5h-18Zm0 3h3.75c0 1.24 1 2.25 2.25 2.25h6c1.24 0 2.25-1 2.25-2.25h3.75c.83 0 1.5.67 1.5 1.5v24c0 .83-.67 1.5-1.5 1.5h-18A1.5 1.5 0 0 1 14 36V12c0-.83.67-1.5 1.5-1.5Zm3 7.5a1.5 1.5 0 0 0 0 3h12a1.5 1.5 0 0 0 0-3h-12Zm0 6a1.5 1.5 0 0 0 0 3h8.25a1.5 1.5 0 0 0 0-3H18.5Zm0 6a1.5 1.5 0 0 0 0 3H29a1.5 1.5 0 0 0 0-3H18.5Z"
				fill="url(#clipboard)"
			/>
			<defs>
				<linearGradient
					id="clipboard"
					x1="19.2"
					y1="-24.13"
					x2="62.03"
					y2="-6.73"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset=".5" stopColor="#EC4899" />
					<stop offset="1" stopColor="#0EA5E9" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default IconClipboardGradient;
