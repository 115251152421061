import { SVGProps } from 'react';

export interface IconMobilePhoneProps extends SVGProps<SVGSVGElement> {
	className?: string;
}

const IconMobilePhone = (props: IconMobilePhoneProps) => {
	const { className, ...remainingProps } = props;

	return (
		<svg
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-label="paper file icon"
			role="graphics-symbol"
			className={className}
			{...remainingProps}
		>
			<path
				d="M13.75 4.3833C11.6819 4.3833 10 6.06518 10 8.1333V31.8833C10 33.9514 11.6819 35.6333 13.75 35.6333H26.25C28.3181 35.6333 30 33.9514 30 31.8833V8.1333C30 6.06518 28.3181 4.3833 26.25 4.3833H13.75ZM13.75 6.8833H26.25C26.94 6.8833 27.5 7.44268 27.5 8.1333V29.3833H12.5V8.1333C12.5 7.44268 13.06 6.8833 13.75 6.8833ZM20 31.2583C20.69 31.2583 21.25 31.8177 21.25 32.5083C21.25 33.1989 20.69 33.7583 20 33.7583C19.31 33.7583 18.75 33.1989 18.75 32.5083C18.75 31.8177 19.31 31.2583 20 31.2583Z"
				fill="black"
			/>
		</svg>
	);
};

export default IconMobilePhone;
