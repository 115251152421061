import { SVGProps } from 'react';

export interface IconLocationTagProps extends SVGProps<SVGSVGElement> {
	className?: string;
}

const IconLocationTag = (props: IconLocationTagProps) => {
	const { className, ...remainingProps } = props;

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 20 20"
			aria-label="map geo location tag icon"
			role="graphics-symbol"
			className={className}
			{...remainingProps}
		>
			<path d="M10 3.125a4.693 4.693 0 0 0-4.687 4.688c0 1.664 1.113 3.39 2.207 4.688H5.338c-.458 0-.89.243-1.125.636l-.863 1.438a1.502 1.502 0 0 0-.02 1.53c.274.482.768.77 1.323.77h10.694c.555 0 1.049-.288 1.322-.77a1.502 1.502 0 0 0-.02-1.53l-.861-1.438a1.32 1.32 0 0 0-1.125-.637h-.93l-1.174 1.25h2.104c.022 0 .042.011.053.03l.863 1.437a.26.26 0 0 1 .003.271.26.26 0 0 1-.235.137H4.653a.261.261 0 0 1-.235-.136.26.26 0 0 1 .003-.271l.863-1.438a.062.062 0 0 1 .053-.03h3.347c.494.485.868.807.918.848a.625.625 0 0 0 .797 0c.175-.145 4.289-3.586 4.289-6.786A4.693 4.693 0 0 0 10 3.125Zm0 1.25a3.442 3.442 0 0 1 3.438 3.438c0 1.933-2.205 4.347-3.438 5.474-1.233-1.127-3.437-3.54-3.437-5.475A3.442 3.442 0 0 1 10 4.376Zm0 2.5a.937.937 0 1 0 0 1.875.937.937 0 0 0 0-1.875Z" />
		</svg>
	);
};

export default IconLocationTag;
