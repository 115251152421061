import * as React from 'react';
import { SVGProps } from 'react';

export interface IconLayoutGradientProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconLayoutGradient = (props: IconLayoutGradientProps) => {
	const { testId = 'IconLayoutGradient' } = props;

	return (
		<svg
			data-testid={testId}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 33 30"
			fill="none"
			aria-label="Layout Icon"
			{...props}
		>
			<path
				fill="url(#a)"
				d="M5.25 0a4.505 4.505 0 0 0-4.5 4.5v21c0 2.482 2.018 4.5 4.5 4.5h22.5c2.482 0 4.5-2.018 4.5-4.5v-21c0-2.482-2.018-4.5-4.5-4.5H5.25Zm0 8.25h22.5c.827 0 1.5.673 1.5 1.5V25.5c0 .827-.673 1.5-1.5 1.5H5.25c-.827 0-1.5-.673-1.5-1.5V9.75c0-.827.673-1.5 1.5-1.5Zm3 2.25a.75.75 0 0 0-.75.75v1.5c0 .414.336.75.75.75H15a.75.75 0 0 0 .75-.75v-1.5a.75.75 0 0 0-.75-.75H8.25Zm7.5 5.25a.75.75 0 0 0-.75.75V18c0 .414.336.75.75.75h9a.75.75 0 0 0 .75-.75v-1.5a.75.75 0 0 0-.75-.75h-9ZM11.25 21a.75.75 0 0 0-.75.75v1.5c0 .414.336.75.75.75h6a.75.75 0 0 0 .75-.75v-1.5a.75.75 0 0 0-.75-.75h-6Z"
			/>
			<defs>
				<linearGradient
					id="a"
					x1={10.314}
					x2={56.089}
					y1={-28.75}
					y2={-4.891}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset={0.5} stopColor="#EC4899" />
					<stop offset={1} stopColor="#0EA5E9" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default IconLayoutGradient;
