import { SVGProps } from 'react';

export interface IconAirProps extends SVGProps<SVGSVGElement> {
	className?: string;
	testId?: string;
}

const IconAir = (props: IconAirProps) => {
	const { testId = 'IconAir', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 40 40"
			fill="none"
			aria-label="Air Icon"
			{...remainingProps}
		>
			<path d="M21.875 32.5C19.29 32.5 17.1875 30.3975 17.1875 27.8125C17.1875 27.1225 17.7475 26.5625 18.4375 26.5625C19.1275 26.5625 19.6875 27.1225 19.6875 27.8125C19.6875 29.0187 20.6688 30 21.875 30C22.4694 30 23.0094 29.7619 23.4037 29.3756C23.81 28.9781 24.0625 28.4244 24.0625 27.8125C24.0625 26.6062 23.0813 25.625 21.875 25.625H6.875C6.185 25.625 5.625 25.065 5.625 24.375C5.625 23.685 6.185 23.125 6.875 23.125H21.875C24.46 23.125 26.5625 25.2275 26.5625 27.8125C26.5625 29.0506 26.08 30.1775 25.2938 31.0162C24.4381 31.9287 23.2219 32.5 21.875 32.5Z" />
			<path d="M6.875 21.25C6.185 21.25 5.62563 20.6912 5.625 20.0012C5.62438 19.3112 6.18313 18.7506 6.87375 18.75L29.7663 18.7231C31.3731 18.7231 32.68 17.4162 32.68 15.81C32.68 14.2037 31.3731 12.8969 29.7675 12.8969C29.165 12.8969 28.605 13.0806 28.14 13.395C27.365 13.9187 26.8544 14.8062 26.8544 15.81C26.8544 16.5 26.2944 17.06 25.6044 17.06C24.9144 17.06 24.3544 16.5 24.3544 15.81C24.3544 14.0244 25.2231 12.4387 26.56 11.4519C27.4581 10.7887 28.5681 10.3969 29.7675 10.3969C32.7519 10.3969 35.18 12.825 35.18 15.81C35.18 18.7944 32.7519 21.2231 29.7675 21.2231L6.875 21.25Z" />
			<path d="M18.125 16.875H6.875C6.185 16.875 5.625 16.315 5.625 15.625C5.625 14.935 6.185 14.375 6.875 14.375H18.125C18.7994 14.375 19.4031 14.0681 19.805 13.5869C20.1219 13.2075 20.3125 12.7194 20.3125 12.1875C20.3125 10.9813 19.3313 10 18.125 10C17.5219 10 16.975 10.2456 16.5788 10.6412C16.1831 11.0375 15.9375 11.5844 15.9375 12.1875C15.9375 12.8775 15.3775 13.4375 14.6875 13.4375C13.9975 13.4375 13.4375 12.8775 13.4375 12.1875C13.4375 10.8025 14.0413 9.55625 14.9988 8.6975C15.8288 7.95313 16.925 7.5 18.125 7.5C20.71 7.5 22.8125 9.6025 22.8125 12.1875C22.8125 13.48 22.2869 14.6519 21.4381 15.5006C20.5894 16.3494 19.4175 16.875 18.125 16.875Z" />
		</svg>
	);
};

export default IconAir;
