export interface IconDocumentGradientProps {
	testId?: string;
}

const IconDocumentGradient = (props: IconDocumentGradientProps) => {
	const { testId = 'IconDocumentGradient' } = props;

	return (
		<svg
			data-testid={testId}
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 40 40"
			aria-label="Document Icon with Magnifying Glass"
			{...props}
		>
			<path
				fill="url(#a)"
				d="M12.5 6.25A3.753 3.753 0 0 0 8.75 10v20a3.753 3.753 0 0 0 3.75 3.75h8.157a8.011 8.011 0 0 1-1.064-2.5H12.5c-.688 0-1.25-.563-1.25-1.25V10c0-.688.563-1.25 1.25-1.25h15c.688 0 1.25.563 1.25 1.25v11.344a7.954 7.954 0 0 1 2.5.825V10a3.753 3.753 0 0 0-3.75-3.75h-15Zm2.813 6.25a.937.937 0 1 0 0 1.875h9.374a.937.937 0 1 0 0-1.875h-9.375Zm0 4.375a.937.937 0 1 0 0 1.875h9.374a.937.937 0 1 0 0-1.875h-9.375Zm0 4.375a.936.936 0 0 0-.938.938c0 .518.419.937.938.937h6.999a8.097 8.097 0 0 1 3.05-1.587.945.945 0 0 0-.674-.288h-9.375ZM27.5 23.125a6.257 6.257 0 0 0-6.25 6.25 6.257 6.257 0 0 0 6.25 6.25 6.208 6.208 0 0 0 3.444-1.039l3.172 3.173c.245.244.564.366.884.366a1.25 1.25 0 0 0 .884-2.134L32.71 32.82a6.208 6.208 0 0 0 1.039-3.444 6.257 6.257 0 0 0-6.25-6.25Zm-12.188 2.5a.936.936 0 0 0-.937.938c0 .518.419.937.938.937h4.28a8.04 8.04 0 0 1 .701-1.875h-4.982Zm12.188 0a3.754 3.754 0 0 1 3.75 3.75 3.754 3.754 0 0 1-3.75 3.75 3.754 3.754 0 0 1-3.75-3.75 3.754 3.754 0 0 1 3.75-3.75ZM25.85 27.5a2.49 2.49 0 0 0-.768 2.5H27.5c.688 0 1.25-.563 1.25-1.25 0-.688-.563-1.25-1.25-1.25h-1.65Z"
			/>
			<defs>
				<linearGradient
					id="a"
					x1="17.099"
					x2="60.042"
					y1="-24.297"
					y2="-5.906"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset=".5" stopColor="#EC4899" />
					<stop offset="1" stopColor="#0EA5E9" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default IconDocumentGradient;
