import { SVGProps } from 'react';

export interface IconDeleteFileProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconDeleteFile = (props: IconDeleteFileProps) => {
	const { testId = 'IconDeleteFile', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
			aria-label="Delete File Icon"
			{...remainingProps}
		>
			<path d="M12.25 3.0625C11.0438 3.0625 10.0625 4.04381 10.0625 5.25V6.5625H5.6875C5.2045 6.5625 4.8125 6.9545 4.8125 7.4375C4.8125 7.9205 5.2045 8.3125 5.6875 8.3125H6.61035L7.34692 21.5828C7.4248 22.9731 8.57509 24.0625 9.96765 24.0625H18.0323C19.4245 24.0625 20.5752 22.9731 20.6531 21.5828L21.3896 8.3125H22.3125C22.7955 8.3125 23.1875 7.9205 23.1875 7.4375C23.1875 6.9545 22.7955 6.5625 22.3125 6.5625H17.9375V5.25C17.9375 4.04381 16.9562 3.0625 15.75 3.0625H12.25ZM12.25 4.8125H15.75C15.9915 4.8125 16.1875 5.00894 16.1875 5.25V6.5625H11.8125V5.25C11.8125 5.00894 12.0085 4.8125 12.25 4.8125ZM8.36206 8.3125H19.6379L18.9056 21.4854C18.8798 21.9491 18.4965 22.3125 18.0323 22.3125H9.96765C9.5039 22.3125 9.12017 21.9495 9.09436 21.4862L8.36206 8.3125ZM14 10.1719C13.5769 10.1719 13.2344 10.5149 13.2344 10.9375V19.6875C13.2344 20.1101 13.5769 20.4531 14 20.4531C14.4231 20.4531 14.7656 20.1101 14.7656 19.6875V10.9375C14.7656 10.5149 14.4231 10.1719 14 10.1719ZM10.7811 10.1727C10.3589 10.1876 10.0284 10.5414 10.0428 10.964L10.3479 19.7516C10.3623 20.165 10.7023 20.4907 11.1127 20.4907C11.1219 20.4907 11.1308 20.4907 11.14 20.4907C11.5622 20.4758 11.8927 20.1212 11.8783 19.6986L11.5732 10.911C11.5584 10.4888 11.2029 10.1627 10.7811 10.1727ZM17.218 10.1727C16.795 10.1609 16.4416 10.4888 16.4268 10.911L16.1217 19.6986C16.1068 20.1212 16.4374 20.4758 16.86 20.4907C16.8692 20.4912 16.8781 20.4907 16.8873 20.4907C17.2977 20.4907 17.6377 20.165 17.6521 19.7516L17.9572 10.964C17.972 10.5414 17.6406 10.1876 17.218 10.1727Z" />
		</svg>
	);
};

export default IconDeleteFile;
