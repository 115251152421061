import { SVGProps } from 'react';

export interface IconChevronDownProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconChevronDown = (props: IconChevronDownProps) => {
	const { testId = 'IconChevronDown', className, ...remainingProps } = props;

	return (
		<svg
			className={className}
			data-testid={testId}
			viewBox="0 0 40 41"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-label="Chevron Down Icon"
			{...remainingProps}
		>
			<path
				d="M19.8428 28.976C19.5059 28.976 19.1834 28.8404 18.9478 28.5992L5.45779 14.7735C4.97591 14.2792 4.98529 13.4879 5.47966 13.0054C5.97341 12.5235 6.76529 12.5335 7.24716 13.0273L19.8422 25.936L32.464 13.0004C32.9465 12.506 33.7384 12.4973 34.2315 12.9785C34.7259 13.4604 34.7359 14.2523 34.2534 14.7467L20.7378 28.5992C20.5022 28.8404 20.1797 28.976 19.8428 28.976Z"
				fill="black"
			/>
		</svg>
	);
};

export default IconChevronDown;
