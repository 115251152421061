import { SVGProps } from 'react';

export interface IconMeasureProps extends SVGProps<SVGSVGElement> {
	className?: string;
	testId?: string;
}

const IconMeasure = (props: IconMeasureProps) => {
	const { testId = 'IconMeasure', className, ...remainingProps } = props;

	return (
		<svg
			className={className}
			data-testid={testId}
			viewBox="0 0 64 64"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-label="Measure Icon"
			{...remainingProps}
		>
			<path d="M39 8.24219C37.664 8.24219 36.4089 8.76303 35.4649 9.70703L9.70705 35.4648C7.75805 37.4138 7.75805 40.5862 9.70705 42.5352L21.4649 54.293C22.4079 55.237 23.664 55.7578 25 55.7578C26.336 55.7578 27.5922 55.237 28.5352 54.293L54.293 28.5352C56.242 26.5862 56.242 23.4138 54.293 21.4648L42.5352 9.70703C41.5922 8.76303 40.336 8.24219 39 8.24219ZM39 12.2422C39.154 12.2422 39.4521 12.2812 39.7071 12.5352L51.4649 24.293C51.8549 24.683 51.8549 25.317 51.4649 25.707L25.7071 51.4648C25.4521 51.7188 25.154 51.7578 25 51.7578C24.846 51.7578 24.548 51.7188 24.293 51.4648L12.5352 39.707C12.1452 39.317 12.1452 38.683 12.5352 38.293L15 35.8281L18.586 39.4141C18.977 39.8051 19.488 40 20 40C20.512 40 21.0231 39.8051 21.4141 39.4141C22.1951 38.6331 22.1951 37.3669 21.4141 36.5859L17.8281 33L21 29.8281L27.586 36.4141C27.977 36.8051 28.488 37 29 37C29.512 37 30.0231 36.8051 30.4141 36.4141C31.1951 35.6331 31.1951 34.3669 30.4141 33.5859L23.8281 27L27 23.8281L30.586 27.4141C30.977 27.8051 31.488 28 32 28C32.512 28 33.0231 27.8051 33.4141 27.4141C34.1951 26.6331 34.1951 25.3669 33.4141 24.5859L29.8281 21L33 17.8281L39.586 24.4141C39.977 24.8051 40.488 25 41 25C41.512 25 42.0231 24.8051 42.4141 24.4141C43.1951 23.6331 43.1951 22.3669 42.4141 21.5859L35.8281 15L38.293 12.5352C38.548 12.2812 38.846 12.2422 39 12.2422Z" />
		</svg>
	);
};

export default IconMeasure;
