import { SVGProps } from 'react';
import cn from 'classnames';

export interface IconChevronLeftProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconChevronLeft = (props: IconChevronLeftProps) => {
	const { testId = 'IconChevronLeft', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			viewBox="0 0 14 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={cn('h-6 w-full', className)}
			aria-label="chevron pointing left"
			{...remainingProps}
		>
			<path d="M12.093 23.802a.996.996 0 0 1-.698-.284L.312 12.705a1 1 0 0 1 0-1.432L11.372.481a1 1 0 0 1 1.397 1.432L2.443 11.99 12.79 22.086a1 1 0 0 1-.698 1.716Z" />
		</svg>
	);
};

export default IconChevronLeft;
