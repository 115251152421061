export interface IconSnowflakeProps {
	testId?: string;
	className?: string;
}

const IconSnowflake = (props: IconSnowflakeProps) => {
	const { testId = 'IconSnowflake', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 32 32"
			aria-label="Snowflake Icon"
			{...remainingProps}
		>
			<path d="M15.938 4.038A1.001 1.001 0 0 0 15 5.1c.013.213.053 1.016.096 2.175l-1.748-1.127a.876.876 0 1 0-.896 1.503l2.7 1.487c.038 1.428.069 3.266.085 5.54a292.508 292.508 0 0 1-4.756-2.843l.063-3.082c0-.02 0-.042-.002-.062a.875.875 0 1 0-1.748.086l.101 2.078A116.325 116.325 0 0 1 7.06 9.684a1.002 1.002 0 1 0-1 1.732c.192.095.906.462 1.93 1.004l-1.848.95-.053.03a.874.874 0 1 0 .906 1.498l2.637-1.594c1.256.68 2.863 1.573 4.841 2.696a293.427 293.427 0 0 1-4.84 2.696l-2.638-1.595a.876.876 0 1 0-.853 1.528l1.85.951c-1.025.542-1.74.909-1.932 1.004a1.002 1.002 0 1 0 1 1.732c.179-.118.854-.555 1.836-1.171l-.102 2.077a.875.875 0 1 0 1.75.025l-.062-3.082a291.857 291.857 0 0 1 4.755-2.844 292.764 292.764 0 0 1-.085 5.54L12.45 24.35a.876.876 0 1 0 .898 1.503l1.747-1.127A115.993 115.993 0 0 1 15 26.9a1.001 1.001 0 1 0 2 0c-.014-.213-.054-1.016-.097-2.175l1.748 1.127.052.03a.876.876 0 1 0 .844-1.533l-2.7-1.487a292.173 292.173 0 0 1-.085-5.54 291.053 291.053 0 0 1 4.756 2.844l-.062 3.081v.062a.875.875 0 1 0 1.75-.086l-.102-2.078c.982.617 1.657 1.053 1.835 1.171a1.003 1.003 0 1 0 1-1.732c-.192-.095-.907-.462-1.932-1.004l1.85-.95.053-.03a.874.874 0 1 0-.905-1.498l-2.64 1.595A293.059 293.059 0 0 1 17.526 16a292.487 292.487 0 0 1 4.84-2.696l2.64 1.594.051.03a.876.876 0 1 0 .801-1.558l-1.85-.95c1.025-.542 1.741-.909 1.933-1.004a1.002 1.002 0 1 0-1.001-1.732c-.178.118-.854.555-1.836 1.171l.102-2.077a.875.875 0 1 0-1.75-.025l.062 3.083a293.243 293.243 0 0 1-4.755 2.843c.017-2.275.047-4.113.085-5.54l2.701-1.488.052-.03a.876.876 0 1 0-.95-1.473l-1.747 1.127c.043-1.159.083-1.962.097-2.175a1.001 1.001 0 0 0-1.062-1.062Z" />
		</svg>
	);
};

export default IconSnowflake;
