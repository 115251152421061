import * as React from 'react';
import { SVGProps } from 'react';

export interface IconOperatorProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconOperator = (props: IconOperatorProps) => {
	const { testId = 'IconOperator' } = props;

	return (
		<svg
			data-testid={testId}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 56 56"
			fill="none"
			aria-label="Operator Icon"
			{...props}
		>
			<path d="M28 8.75c-10.255 0-16.625 6.035-16.625 15.75v4.242s-.875 1.495-.875 3.633c0 2.432 1.532 4.18 3.341 5.248C15.551 44.043 23.64 49 28 49c4.32 0 12.482-4.77 14.164-11.375h.055a4.161 4.161 0 0 0 4.156-4.156v-3.063a4.146 4.146 0 0 0-1.75-3.377V24.5c-.157-10.214-8.265-12.207-8.265-12.207S33.961 8.75 28 8.75Zm0 3.5c5.303 0 6.133 3.203 6.133 3.203s6.992.297 6.992 9.047v1.75h-.766c-.89-2.269-2.609-5.318-6.159-7.277-.142-.079-.437-.23-.437-.23s-.2.265-.301.393c-4.072 5.122-10.6 5.364-16.169 5.364l-1.75 3.5h-.668v-3.5c0-7.788 4.786-12.25 13.125-12.25Zm6.316 11.06c1.183 1.064 2.044 2.602 2.64 4.006a19.994 19.994 0 0 1 1.106 3.461v3.93c-1.847 1.797-4.696 2.69-7.175 3a2.608 2.608 0 0 0-2.012-.957 2.625 2.625 0 1 0 1.842 4.491c2.258-.215 4.776-.826 6.987-2.011-2.38 3.725-7.453 6.27-9.704 6.27-2.992 0-10.982-4.839-10.982-10.29 0 0-3.018-.866-3.018-2.835 0-.323.039-.609.09-.875h3.617l1.753-3.51c4.635-.087 10.634-.815 14.856-4.68Z" />
		</svg>
	);
};

export default IconOperator;
