import { SVGProps } from 'react';

export interface IconLightningGradientProps extends SVGProps<SVGSVGElement> {
	testId?: string;
	className?: string;
}

const IconLightningGradient = (props: IconLightningGradientProps) => {
	const { testId = 'IconLightningGradient', className } = props;

	return (
		<svg
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 48 48"
			data-testid={testId}
			className={className}
			aria-label="lightning icon"
		>
			<path
				d="M22.28 41.92c-.33 0-.66-.06-1-.17a2.97 2.97 0 0 1-1.99-3.25l1.49-10.75h-4.04a3 3 0 0 1-2.97-3.44l2.34-15.75A3.02 3.02 0 0 1 19.08 6h7.76a3 3 0 0 1 2.83 4l-2.8 8h5.78c1.08 0 2.05.56 2.6 1.5.54.94.54 2.07 0 3L24.85 40.42a2.97 2.97 0 0 1-2.58 1.5ZM19.4 9a.38.38 0 0 0-.37.32l-2.23 15a.38.38 0 0 0 .37.43h5.33a1.5 1.5 0 0 1 1.49 1.7l-1.62 11.73c-.01.08.03.14.1.17.06.02.14 0 .18-.07l9.67-16.72A.37.37 0 0 0 32 21h-7.25a1.5 1.5 0 0 1-1.42-2l3.33-9.5a.37.37 0 0 0-.35-.5h-6.9Z"
				fill="url(#lightning)"
			/>
			<defs>
				<linearGradient
					id="lightning"
					x1="20.39"
					y1="-28.42"
					x2="57.48"
					y2="-17.19"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset=".5" stopColor="#EC4899" />
					<stop offset="1" stopColor="#0EA5E9" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default IconLightningGradient;
