import { SVGProps } from 'react';

export interface IconGiftProps extends SVGProps<SVGSVGElement> {
	className?: string;
	testId?: string;
}

const IconGift = (props: IconGiftProps) => {
	const { testId = 'IconGift', className, ...remainingProps } = props;

	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			aria-label="gift icon"
			data-testid={testId}
			className={className}
			{...remainingProps}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M8.25 4.75H9.75V16.75H8.25V4.75Z" fill="#0284C7" />
			<path
				d="M10.875 0.25C12.3221 0.25 13.5 1.4275 13.5 2.875C13.5 4.3225 12.3221 5.5 10.875 5.5H7.125C5.67788 5.5 4.5 4.3225 4.5 2.875C4.5 1.4275 5.67788 0.25 7.125 0.25C7.85925 0.25 8.523 0.55375 9 1.04163C9.477 0.55375 10.1407 0.25 10.875 0.25ZM8.25 4V2.875C8.25 2.25475 7.74525 1.75 7.125 1.75C6.50475 1.75 6 2.25475 6 2.875C6 3.49525 6.50475 4 7.125 4H8.25ZM10.875 4C11.4952 4 12 3.49525 12 2.875C12 2.25475 11.4952 1.75 10.875 1.75C10.2548 1.75 9.75 2.25475 9.75 2.875V4H10.875Z"
				fill="#0284C7"
			/>
			<path
				d="M15 8.5H16.5V14.5C16.5 16.1541 15.1541 17.5 13.5 17.5H4.5C2.84588 17.5 1.5 16.1541 1.5 14.5V8.5H3V14.5C3 15.3272 3.67275 16 4.5 16H13.5C14.3272 16 15 15.3272 15 14.5V8.5Z"
				fill="#0284C7"
			/>
			<path
				d="M15.75 4C16.7839 4 17.625 4.84113 17.625 5.875V7.375C17.625 8.40887 16.7839 9.25 15.75 9.25H2.25C1.21613 9.25 0.375 8.40887 0.375 7.375V5.875C0.375 4.84113 1.21613 4 2.25 4H15.75ZM16.125 7.375V5.875C16.125 5.66838 15.957 5.5 15.75 5.5H2.25C2.043 5.5 1.875 5.66838 1.875 5.875V7.375C1.875 7.58162 2.043 7.75 2.25 7.75H15.75C15.957 7.75 16.125 7.58162 16.125 7.375Z"
				fill="#0284C7"
			/>
		</svg>
	);
};

export default IconGift;
