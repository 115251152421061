import classNames from 'classnames';
import { SVGProps } from 'react';

export interface IconThreeGradientProps extends SVGProps<SVGSVGElement> {
	className?: string;
	testId?: string;
}

const IconThreeGradient = (props: IconThreeGradientProps) => {
	const { className, testId = 'IconThreeGradient', ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 37 36"
			fill="none"
			className={classNames(className)}
			aria-label="step number 3"
			{...remainingProps}
		>
			<path
				fill="url(#icon_three_gradient)"
				d="M18.5 0C8.56 0 .5 8.06.5 18s8.06 18 18 18 18-8.06 18-18-8.06-18-18-18Zm0 3.273c8.134 0 14.727 6.593 14.727 14.727S26.634 32.727 18.5 32.727c-8.133 0-14.727-6.593-14.727-14.727S10.367 3.273 18.5 3.273Zm-.08 8.105c-2.24 0-3.681 1.037-4.244 2.277a2.003 2.003 0 0 0-.194.852c0 .642.38 1.074 1.117 1.074.598 0 .906-.221 1.196-.819.413-.896 1.062-1.345 2.135-1.345 1.283 0 1.968.678 1.968 1.697 0 1.037-.862 1.756-2.189 1.756h-.518c-.641 0-1.011.387-1.011.941 0 .572.37.95 1.011.95h.553c1.538 0 2.445.685 2.436 1.916 0 1.054-.897 1.81-2.198 1.81-1.318 0-2.004-.526-2.453-1.414-.263-.484-.597-.695-1.124-.695-.73 0-1.169.43-1.169 1.115 0 .265.061.546.202.836.597 1.248 2.083 2.293 4.501 2.295 2.945 0 4.94-1.53 4.94-3.832 0-1.75-1.223-2.857-3.113-3.033v-.051c1.494-.308 2.646-1.363 2.646-2.971 0-2.03-1.811-3.36-4.492-3.36Z"
			/>
			<defs>
				<linearGradient
					id="icon_three_gradient"
					x1="11.43"
					x2="64.805"
					y1="-34.5"
					y2="-8.005"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset=".5" stopColor="#EC4899" />
					<stop offset="1" stopColor="#0EA5E9" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default IconThreeGradient;
