export interface IconHeatPumpProps {
	testId?: string;
	className?: string;
}

const IconHeatPump = (props: IconHeatPumpProps) => {
	const { testId = 'IconHeatPump', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 32 32"
			aria-label="Heat Pump Icon"
			{...remainingProps}
		>
			<path d="M10.666 8.667C7 8.667 4 11.667 4 15.333 4 19 7 22 10.666 22c3.667 0 6.667-3 6.667-6.667 0-3.666-3-6.666-6.667-6.666Zm0 11.333A4.638 4.638 0 0 1 6 15.333c0-2.6 2.066-4.666 4.666-4.666 2.6 0 4.667 2.066 4.667 4.666 0 2.6-2.067 4.667-4.667 4.667ZM26.266 11h-6.533c-.6 0-1.067.467-1.067 1 0 .533.467 1 1.067 1h6.533c.6 0 1.067-.467 1.067-1 0-.533-.467-1-1.067-1ZM26.266 7.667h-6.533c-.6 0-1.067.466-1.067 1 0 .533.467 1 1.067 1h6.533c.6 0 1.067-.467 1.067-1 0-.534-.467-1-1.067-1ZM26.266 14.333h-6.533c-.6 0-1.067.467-1.067 1 0 .534.467 1 1.067 1h6.533c.6 0 1.067-.466 1.067-1 0-.533-.467-1-1.067-1ZM26.266 17.667h-6.533c-.6 0-1.067.466-1.067 1 0 .533.467 1 1.067 1h6.533c.6 0 1.067-.467 1.067-1 0-.534-.467-1-1.067-1ZM26.266 21h-6.533c-.6 0-1.067.467-1.067 1 0 .533.467 1 1.067 1h6.533c.6 0 1.067-.467 1.067-1 0-.533-.467-1-1.067-1Z" />
			<path d="M28 4.667H4a2.675 2.675 0 0 0-2.667 2.666v16C1.333 24.8 2.533 26 4 26h.666L6 28h2l1.333-2h13.333L24 28h2l1.333-2H28c1.466 0 2.666-1.2 2.666-2.667v-16c0-1.466-1.2-2.666-2.666-2.666Zm.666 18.666c0 .4-.266.667-.666.667H4c-.4 0-.667-.267-.667-.667v-16c0-.4.267-.666.667-.666h24c.4 0 .666.266.666.666v16Z" />
			<path d="M13.666 15.533c-.466-.266-1.733-.6-2.466-.466.466-.534.866-1.8.866-2.4 0-.734-.6-1.334-1.333-1.334s-1.333.6-1.333 1.334c0 .533.333 1.8.866 2.4-.733-.134-2 .2-2.466.466-.667.334-.867 1.2-.467 1.8.4.6 1.2.867 1.8.467.467-.267 1.4-1.2 1.667-1.933.266.733 1.133 1.666 1.666 1.933.667.333 1.467.133 1.8-.467.334-.6 0-1.466-.6-1.8Z" />
		</svg>
	);
};

export default IconHeatPump;
