import { SVGProps } from 'react';

export interface IconPeopleProps extends SVGProps<SVGSVGElement> {
	className?: string;
	testId?: string;
}

const IconPeople = (props: IconPeopleProps) => {
	const { testId = 'IconPeople' } = props;

	return (
		<svg
			data-testid={testId}
			viewBox="0 0 57 56"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-label="People Icon"
			{...props}
		>
			<path d="M12.917 26.25c-2.895 0-5.25-2.552-5.25-5.688 0-3.136 2.355-5.687 5.25-5.687 2.896 0 5.25 2.552 5.25 5.688 0 3.136-2.355 5.687-5.25 5.687ZM20.275 37.625H5.554c-1.162 0-2.41-.986-2.249-2.46C3.748 31.08 7.881 28 12.917 28c5.032 0 9.164 3.077 9.612 7.158.16 1.487-1.09 2.467-2.254 2.467ZM51.775 37.625H37.054c-1.162 0-2.41-.986-2.249-2.46C35.248 31.08 39.381 28 44.417 28c5.032 0 9.164 3.077 9.612 7.158.16 1.487-1.09 2.467-2.254 2.467ZM44.417 26.25c-2.895 0-5.25-2.552-5.25-5.688 0-3.136 2.355-5.687 5.25-5.687s5.25 2.552 5.25 5.688c0 3.136-2.355 5.687-5.25 5.687Z" />
			<path d="M38.67 46.375H19.54c-2.77 0-4.916-2.892-3.617-5.589 2.17-4.506 7.344-7.417 13.182-7.417 5.837 0 11.012 2.911 13.181 7.417 1.362 2.828-.986 5.589-3.617 5.589Zm-9.565-9.506c-4.432 0-8.482 2.176-10.03 5.435-.14.296.19.571.465.571h19.128c.273 0 .606-.274.465-.57-1.547-3.26-5.597-5.436-10.028-5.436ZM28.667 31.5c-3.86 0-7-3.336-7-7.438 0-4.1 3.14-7.437 7-7.437s7 3.336 7 7.438c0 4.1-3.14 7.437-7 7.437Zm0-11.375c-1.93 0-3.5 1.767-3.5 3.938 0 2.17 1.57 3.937 3.5 3.937s3.5-1.767 3.5-3.938c0-2.17-1.57-3.937-3.5-3.937Z" />
		</svg>
	);
};

export default IconPeople;
