import { SVGProps } from 'react';

export interface IconLeafProps extends SVGProps<SVGSVGElement> {
	className?: string;
	testId?: string;
}

const IconLeaf = (props: IconLeafProps) => {
	const { testId = 'IconLeaf', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			className={className}
			viewBox="0 0 64 64"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-label="Leaf Icon"
			{...remainingProps}
		>
			<path d="M11.8613 12.0058C11.6922 12.0175 11.5236 12.0497 11.3594 12.1054C10.6994 12.3284 10.2048 12.8786 10.0508 13.5566C7.08578 26.6006 10.7699 34.7328 14.3809 39.2578C19.3229 45.4498 27.385 49 36.5 49C41.0986 49 45.2607 47.1059 48.2559 44.0605C49.6929 47.1605 49.9486 50.1835 50.1016 50.8359C50.4806 52.4509 52.0979 53.4532 53.7129 53.0742C55.3279 52.6952 56.3302 51.0778 55.9512 49.4628C55.1337 45.9817 53.7784 42.44 51.4492 39.4394C52.4343 37.3264 53 34.9809 53 32.5C53 23.402 45.598 16 36.5 16C33.644 16 31.175 16.2506 28.998 16.4726C22.932 17.0876 19.2732 17.4588 13.2812 12.4648C12.8807 12.131 12.3688 11.9708 11.8613 12.0058ZM13.3672 17.5195C19.3382 21.4695 24.0514 20.9951 29.4004 20.4511C31.5864 20.2291 33.846 20 36.5 20C43.393 20 49 25.607 49 32.5C49 33.84 48.7827 35.1287 48.3906 36.3398C46.5931 34.9081 44.4058 33.703 41.668 32.7714C38.193 31.5874 34.7239 31.0991 31.5019 30.3261C29.8979 29.9341 28.3557 29.4604 27.0117 28.7324C24.7917 27.5264 23.9903 25.7441 23.9023 25.5781C23.6713 25.1321 23.1382 24.9195 22.6582 25.1015C22.1412 25.2975 21.8821 25.8755 22.0781 26.3925C22.7601 28.1895 24.0996 29.7916 25.6816 30.8496C27.2636 31.9186 28.9745 32.6141 30.6465 33.2031C33.9975 34.3511 37.2921 35.1611 40.2051 36.4941C42.4984 37.5163 44.482 38.879 46.0117 40.5898C43.7172 43.2835 40.3078 45 36.5 45C28.608 45 21.6859 41.9967 17.5059 36.7617C13.6169 31.8887 12.2062 25.2825 13.3672 17.5195Z" />
		</svg>
	);
};

export default IconLeaf;
