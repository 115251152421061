import { SVGProps } from 'react';

export interface IconHeartInHandProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconHeartInHand = (props: IconHeartInHandProps) => {
	const { testId = 'IconHeartInHand', ...svgProps } = props;

	if (!svgProps.className?.includes('fill'))
		console.warn('IconHeartInHand: fill class missing');

	return (
		<svg
			data-testid={testId}
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 56 56"
			aria-label="Heart in hand"
			{...svgProps}
		>
			<path d="M27.56 3.5a4.82 4.82 0 0 0-4.43 2.94 4.82 4.82 0 0 0-6.5 4.5v14.54l-.9-1.54c-1.7-2.93-4.34-3.33-6.25-2.36-1.67.84-3 3-1.74 6.53.43 1.21 3.84 10.46 4.88 12.8.82 1.84 4.52 10.65 15.82 10.63 3.62 0 16.3-1.13 16.18-19.17V17.06a4.82 4.82 0 0 0-6.12-4.63v-1.5a4.82 4.82 0 0 0-6.5-4.5 4.82 4.82 0 0 0-4.44-2.93Zm0 3.5c.73 0 1.32.59 1.32 1.31V25.37a1.75 1.75 0 0 0 3.5 0V10.94a1.31 1.31 0 0 1 2.62 0V26.25a1.75 1.75 0 0 0 3.5 0v-9.19a1.31 1.31 0 0 1 2.63 0v15.31c0 12.94-6.94 15.73-12.7 15.72-3.3 0-9.28-1.05-12.61-8.6-.99-2.22-4.38-11.4-4.78-12.55-.46-1.3-.33-2.07.02-2.23.59-.3 1.11-.02 1.56.84.33.64 3.53 6.98 3.67 7.19a2.1 2.1 0 0 0 2.37.95c.7-.21 1.46-.9 1.46-2.2V10.95a1.31 1.31 0 0 1 2.63 0v14.43a1.75 1.75 0 0 0 3.5 0V8.31c0-.72.59-1.31 1.31-1.31Zm-1.23 24.67a3.58 3.58 0 0 0-3.58 3.58c0 3.58 6.56 7.58 6.56 7.58s6.57-4 6.57-7.58c0-1.97-1.6-3.58-3.59-3.58-1.81 0-2.98 1.31-2.98 1.31s-1.16-1.3-2.98-1.3Z" />
		</svg>
	);
};

export default IconHeartInHand;
