export interface IconFlameProps {
	testId?: string;
	className?: string;
}

const IconFlame = (props: IconFlameProps) => {
	const { testId = 'IconFlame', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 32 32"
			aria-label="Flame Icon"
			{...remainingProps}
		>
			<path d="M15.129 3.695a2.521 2.521 0 0 0-2.36 1.766c-.944 2.962-2.263 4.716-3.426 6.264-1.203 1.6-2.34 3.111-2.71 5.472-.464 2.963.427 6.099 2.385 8.388C10.902 27.787 13.56 29 16.5 29c5.682 0 9.5-4.119 9.5-10.25 0-5.438-3.655-11.065-9.537-14.685a2.468 2.468 0 0 0-1.334-.37Zm.061 1.994c.074.008.15.033.223.079C20.629 8.977 24 14.073 24 18.75c0 2.27-.61 4.17-1.694 5.576a6.69 6.69 0 0 0 .194-1.576c0-6.166-4.206-9.523-4.384-9.663a1 1 0 0 0-1.602.952c.245 1.483.325 4.011-.282 4.727-.071.084-.125.11-.232.11-.247 0-.81-1.047-1.013-2.284a1 1 0 0 0-1.737-.503c-2.273 2.576-2.75 5.044-2.75 6.661 0 .61.092 1.203.258 1.766-.073-.078-.15-.15-.22-.23-1.584-1.852-2.304-4.386-1.929-6.78.293-1.867 1.193-3.064 2.332-4.58 1.197-1.59 2.683-3.573 3.734-6.858.084-.265.293-.403.515-.38Zm3.508 11.051a10.787 10.787 0 0 1 1.802 6.01c0 2.06-1.402 4.25-4 4.25-2.131 0-4-1.986-4-4.25 0-.955.22-2.297 1.116-3.799.447.98 1.187 1.924 2.384 1.924.698 0 1.306-.283 1.759-.817.674-.796.9-2.092.939-3.318Z" />
		</svg>
	);
};

export default IconFlame;
