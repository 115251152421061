import { SVGProps } from 'react';
import cn from 'classnames';

export interface IconChevronProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconChevron = (props: IconChevronProps) => {
	const { testId = 'IconChevron', className, ...remainingProps } = props;

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			data-testid={testId}
			fill="none"
			viewBox="0 0 26 24"
			className={cn('h-6 w-6', className)}
			role="img"
			aria-label="chevron pointing left"
			{...remainingProps}
		>
			<path
				fill="#fff"
				fillRule="evenodd"
				d="M16.895 4.094a1.148 1.148 0 0 1 0 1.66l-7.22 6.996 7.22 6.996a1.148 1.148 0 0 1 0 1.66 1.24 1.24 0 0 1-1.713 0L7.105 13.58a1.148 1.148 0 0 1 0-1.66l8.077-7.826a1.24 1.24 0 0 1 1.713 0Z"
				clipRule="evenodd"
			/>
		</svg>
	);
};

export default IconChevron;
