import { SVGProps } from 'react';

export interface IconGuaranteeSealGradientProps
	extends SVGProps<SVGSVGElement> {
	testId?: string;
	className?: string;
}

const IconGuaranteeSealGradient = (props: IconGuaranteeSealGradientProps) => {
	const { testId = 'IconGuaranteeSealGradient', className } = props;

	return (
		<svg
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 49 48"
			data-testid={testId}
			className={className}
			aria-label="guarantee seal icon"
		>
			<path
				d="M24.5 6.02a3 3 0 0 0-2.35 1.12l-1.31 1.62c-.56.7-1.47 1-2.33.76l-2.02-.54c-1.86-.5-3.7.84-3.8 2.76l-.1 2.08a2.25 2.25 0 0 1-1.45 1.99l-1.95.75a3.02 3.02 0 0 0-1.45 4.46l1.14 1.75c.48.75.48 1.71 0 2.46l-1.14 1.75a3.02 3.02 0 0 0 1.45 4.46l1.95.75c.83.32 1.4 1.1 1.44 1.99l.11 2.08a3.02 3.02 0 0 0 3.8 2.76l2.02-.54c.86-.23 1.77.07 2.33.76l1.31 1.62a3.02 3.02 0 0 0 4.7 0l1.31-1.62c.56-.7 1.48-1 2.34-.76l2.01.54c1.86.5 3.7-.84 3.8-2.76l.1-2.08c.05-.9.62-1.67 1.45-1.99l1.95-.75a3.02 3.02 0 0 0 1.45-4.46l-1.14-1.75a2.25 2.25 0 0 1 0-2.46l1.14-1.75a3.02 3.02 0 0 0-1.45-4.46l-1.95-.75a2.25 2.25 0 0 1-1.44-1.99l-.1-2.08a3.02 3.02 0 0 0-3.8-2.76l-2.02.54a2.25 2.25 0 0 1-2.34-.76l-1.31-1.62a3 3 0 0 0-2.35-1.12Zm0 2.98c.25 0 .5.11.68.33l1.07 1.33a4.5 4.5 0 0 0 4.67 1.52l1.65-.45a.87.87 0 0 1 1.1.8l.1 1.7a4.5 4.5 0 0 0 2.88 3.98l1.6.61c.51.2.72.83.41 1.3l-.93 1.43a4.5 4.5 0 0 0 0 4.9l.93 1.44c.3.46.1 1.1-.42 1.29l-1.6.61a4.5 4.5 0 0 0-2.88 3.97l-.09 1.71a.87.87 0 0 1-1.1.8l-1.65-.45a4.5 4.5 0 0 0-4.67 1.52l-1.07 1.33a.87.87 0 0 1-1.36 0l-1.07-1.33a4.5 4.5 0 0 0-4.67-1.52l-1.65.45a.87.87 0 0 1-1.1-.8l-.1-1.7a4.5 4.5 0 0 0-2.88-3.98l-1.6-.61a.87.87 0 0 1-.41-1.3l.93-1.43a4.5 4.5 0 0 0 0-4.9l-.93-1.44a.87.87 0 0 1 .42-1.29l1.6-.61a4.5 4.5 0 0 0 2.88-3.97l.09-1.71a.87.87 0 0 1 1.1-.8l1.65.45a4.5 4.5 0 0 0 4.67-1.52l1.07-1.33A.87.87 0 0 1 24.5 9Zm5.53 8.26c-.48 0-.95.25-1.23.68l-6.2 9.58-2.53-2.87a1.5 1.5 0 0 0-2.25 1.98L21.65 31a1.5 1.5 0 0 0 2.38-.17l7.29-11.24a1.5 1.5 0 0 0-1.3-2.32Z"
				fill="url(#checkmark-seal)"
			/>
			<defs>
				<linearGradient
					id="checkmark-seal"
					x1="17.72"
					y1="-28.45"
					x2="69.7"
					y2="-3.7"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset=".5" stopColor="#EC4899" />
					<stop offset="1" stopColor="#0EA5E9" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default IconGuaranteeSealGradient;
