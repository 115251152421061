import { SVGProps } from 'react';

export interface IconCircleQuestionMarkProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconCircleQuestionMark = (props: IconCircleQuestionMarkProps) => {
	const { testId = 'IconCircleQuestionMark', ...otherProps } = props;

	return (
		<svg
			data-testid={testId}
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 40 40"
			{...otherProps}
			aria-label="Question mark in a circle"
		>
			<path d="M20 6.25C12.406 6.25 6.25 12.406 6.25 20S12.406 33.75 20 33.75 33.75 27.594 33.75 20 27.594 6.25 20 6.25Zm0 2.5c6.213 0 11.25 5.037 11.25 11.25S26.213 31.25 20 31.25 8.75 26.213 8.75 20 13.787 8.75 20 8.75Zm.162 4.269c-2.243 0-4.444 1.175-4.444 2.945 0 .33.206 1.022 1.039 1.022.453 0 .75-.183 1.005-.437.57-.568.924-1.487 2.315-1.487.556 0 1.838.282 1.838 1.618 0 2.02-3.405 1.94-3.405 4.818 0 .9.394 1.295 1.21 1.295 1.29 0 1.225-1.058 1.225-1.174 0-2.073 3.627-1.862 3.627-5.179 0-1.665-1.526-3.421-4.41-3.421Zm-.477 10.863c-.96 0-1.567.81-1.567 1.499 0 .688.587 1.499 1.567 1.499s1.567-.767 1.567-1.5c0-.731-.608-1.498-1.567-1.498Z" />
		</svg>
	);
};

export default IconCircleQuestionMark;
