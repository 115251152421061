import { SVGProps } from 'react';

export interface IconRibbonProps extends SVGProps<SVGSVGElement> {
	className?: string;
	testId?: string;
}

const IconRibbon = (props: IconRibbonProps) => {
	const { testId = 'IconRibbon' } = props;

	return (
		<svg
			data-testid={testId}
			viewBox="0 0 56 56"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-label="Ribbon Icon"
			{...props}
		>
			<path d="M28 5.212a3.481 3.481 0 0 0-2.722 1.3l-1.921 2.38-2.96-.795a3.5 3.5 0 0 0-4.404 3.2l-.156 3.053-2.859 1.096a3.5 3.5 0 0 0-1.68 5.178l1.67 2.564-1.67 2.563a3.5 3.5 0 0 0 1.68 5.178l2.86 1.096.155 3.052c.077 1.5 1.081 2.677 2.382 3.119v11.679c0 .62.328 1.193.863 1.507a1.757 1.757 0 0 0 1.738.023L28 47.5l7.024 3.904a1.765 1.765 0 0 0 1.74-.021c.533-.315.861-.89.861-1.509v-11.68c1.302-.441 2.306-1.617 2.382-3.118l.158-3.052 2.857-1.096a3.5 3.5 0 0 0 1.68-5.178l-1.668-2.564 1.668-2.563a3.5 3.5 0 0 0-1.68-5.178l-2.857-1.096-.158-3.054a3.5 3.5 0 0 0-4.404-3.199l-2.958.795-1.923-2.38A3.481 3.481 0 0 0 28 5.213Zm0 4.017a.87.87 0 0 1 .68.326l2.316 2.864a.875.875 0 0 0 .907.296l3.564-.957a.875.875 0 0 1 1.1.8l.188 3.677a.877.877 0 0 0 .56.773l3.443 1.32c.52.199.724.828.42 1.295l-2.01 3.088a.876.876 0 0 0 0 .955l2.01 3.088a.875.875 0 0 1-.42 1.294l-3.442 1.32a.874.874 0 0 0-.56.772l-.189 3.677a.875.875 0 0 1-1.1.802l-3.564-.957a.873.873 0 0 0-.907.294l-2.316 2.866a.873.873 0 0 1-1.36 0l-2.316-2.866a.874.874 0 0 0-.907-.294l-3.564.957a.876.876 0 0 1-1.1-.802l-.188-3.677a.877.877 0 0 0-.56-.773l-3.442-1.32a.875.875 0 0 1-.42-1.293l2.009-3.088a.876.876 0 0 0 0-.955l-2.01-3.088a.877.877 0 0 1 .42-1.296l3.442-1.32a.874.874 0 0 0 .561-.772l.188-3.677a.874.874 0 0 1 1.1-.8l3.564.957c.334.09.69-.026.907-.296l2.316-2.864a.87.87 0 0 1 .68-.326Zm0 6.084c-4.343 0-7.875 3.533-7.875 7.874 0 4.342 3.532 7.875 7.875 7.875s7.875-3.533 7.875-7.875c0-4.341-3.532-7.875-7.875-7.875Zm0 3.5a4.38 4.38 0 0 1 4.375 4.375A4.38 4.38 0 0 1 28 27.563a4.38 4.38 0 0 1-4.375-4.375A4.38 4.38 0 0 1 28 18.813Zm-4.643 18.67 1.92 2.38a3.5 3.5 0 0 0 5.445 0l1.923-2.38 1.48.398v9.02l-5.276-2.93a1.754 1.754 0 0 0-1.7 0l-5.274 2.93v-9.02l1.482-.398Z" />
		</svg>
	);
};

export default IconRibbon;
