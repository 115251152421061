import { SVGProps } from 'react';

export interface IconPrintProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconPrint = (props: IconPrintProps) => {
	const { testId = 'IconPrint', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			className={className}
			viewBox="0 0 13 13"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-label="Printer Icon"
			{...remainingProps}
		>
			<path d="M10.1562 3.1875H9.03125V1.78125C9.03125 1.626 8.90525 1.5 8.75 1.5H4.25C4.09475 1.5 3.96875 1.626 3.96875 1.78125V3.07697H2.84375V1.5C2.84375 0.878719 3.34747 0.375 3.96875 0.375H9.03125C9.65253 0.375 10.1562 0.878719 10.1562 1.5V3.1875Z" />
			<path d="M10.7188 10.5H9.3125V9.375H10.7188C11.029 9.375 11.2812 9.12272 11.2812 8.8125V4.03125C11.2812 3.72103 11.029 3.46875 10.7188 3.46875H2.28125C1.97103 3.46875 1.71875 3.72103 1.71875 4.03125V8.8125C1.71875 9.12272 1.97103 9.375 2.28125 9.375H3.6875V10.5H2.28125C1.35059 10.5 0.59375 9.74316 0.59375 8.8125V4.03125C0.59375 3.10059 1.35059 2.34375 2.28125 2.34375H10.7188C11.6494 2.34375 12.4062 3.10059 12.4062 4.03125V8.8125C12.4062 9.74316 11.6494 10.5 10.7188 10.5Z" />
			<path d="M9.03125 12.1875H3.96875C3.34831 12.1875 2.84375 11.6829 2.84375 11.0625V7.40625C2.84375 6.78581 3.34831 6.28125 3.96875 6.28125H9.03125C9.65169 6.28125 10.1562 6.78581 10.1562 7.40625V11.0625C10.1562 11.6829 9.65169 12.1875 9.03125 12.1875ZM3.96875 7.6875V10.7812C3.96875 10.9365 4.09475 11.0625 4.25 11.0625H8.75084C8.90609 11.0625 9.03209 10.9365 9.03209 10.7812L9.03125 7.6875C9.03125 7.53225 8.90525 7.40625 8.75 7.40625H4.25C4.09475 7.40625 3.96875 7.53225 3.96875 7.6875Z" />
			<path d="M9.59375 5.4375C9.90441 5.4375 10.1562 5.18566 10.1562 4.875C10.1562 4.56434 9.90441 4.3125 9.59375 4.3125C9.28309 4.3125 9.03125 4.56434 9.03125 4.875C9.03125 5.18566 9.28309 5.4375 9.59375 5.4375Z" />
		</svg>
	);
};

export default IconPrint;
