import { SVGProps } from 'react';

export interface IconAdvisorProps extends SVGProps<SVGSVGElement> {
	className?: string;
}

const IconAdvisor = (props: IconAdvisorProps) => {
	const { className, ...remainingProps } = props;

	return (
		<svg
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-label="Advisor icon"
			className={className}
			{...remainingProps}
		>
			<path d="M12 3.75c-4.395 0-7.125 2.586-7.125 6.75v1.818s-.375.64-.375 1.557c0 1.043.656 1.792 1.432 2.25C6.665 18.874 10.132 21 12 21c1.851 0 5.35-2.044 6.07-4.875h.024c.982 0 1.781-.8 1.781-1.781V13.03c0-.597-.298-1.124-.75-1.447V10.5c-.067-4.377-3.542-5.232-3.542-5.232S14.555 3.75 12 3.75Zm0 1.5c2.273 0 2.629 1.373 2.629 1.373s2.996.127 2.996 3.877v.75h-.328c-.382-.972-1.118-2.28-2.64-3.119a10.404 10.404 0 0 0-.187-.099s-.086.114-.13.169C12.597 10.396 9.799 10.5 7.412 10.5l-.75 1.5h-.286v-1.5c0-3.338 2.051-5.25 5.625-5.25Zm2.707 4.74c.507.456.876 1.115 1.13 1.717.337.791.476 1.483.476 1.483v1.684c-.792.77-2.013 1.153-3.076 1.286a1.118 1.118 0 0 0-.862-.41 1.125 1.125 0 1 0 .79 1.925c.967-.092 2.046-.354 2.994-.862C15.139 18.409 12.965 19.5 12 19.5c-1.283 0-4.707-2.074-4.707-4.41 0 0-1.293-.371-1.293-1.215 0-.139.017-.26.039-.375h1.55l.751-1.504c1.986-.037 4.558-.35 6.367-2.006Z" />
		</svg>
	);
};

export default IconAdvisor;
