import { SVGProps } from 'react';

export interface IconHandshakeProps extends SVGProps<SVGSVGElement> {
	className?: string;
}

const IconHandshake = (props: IconHandshakeProps) => {
	const { className, ...remainingProps } = props;

	return (
		<svg
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-label="Handshake icon"
			className={className}
			{...remainingProps}
		>
			<path d="M9.26 5.25c-.767 0-1.515.144-2.226.428A4.47 4.47 0 0 1 5.366 6h-.238c-1.154 0-2.161.74-2.505 1.842l-.903 2.892a4.495 4.495 0 0 0 1.068 4.478 255.377 255.377 0 0 0 2.85 2.9l.199.197c.937.93 2.326 2.308 3.805 2.308.094 0 .189-.006.284-.018.575-.07 1.076-.352 1.495-.836.278.091.57.136.87.101.632-.074 1.182-.465 1.644-1.154.58.2 1.117.063 1.238.03.644-.177 1.18-.622 1.533-1.252a2.062 2.062 0 0 0 1.58-.398l.002.002 2.759-2.148a3.767 3.767 0 0 0 1.275-4.078l-.945-3.024A2.614 2.614 0 0 0 18.872 6h-.238a4.47 4.47 0 0 1-1.668-.322 5.972 5.972 0 0 0-2.226-.428h-2.306c-.02 0-.04.002-.059.003V5.25H9.259Zm0 1.5h.816c-.539.54-.9.764-1.124.848-.793.3-1.78.672-1.77 2.077.005.552.34 1.064.92 1.404 1.161.68 2.958.513 4.878-.419l4.45 4.397a.563.563 0 0 1-.794.795l-1.99-1.988a.75.75 0 1 0-1.06 1.06l1.82 1.82c-.158.285-.377.48-.63.55-.182.05-.36.024-.466-.067-.42-.367-.868-.83-1.487-1.47l-.26-.268a.75.75 0 1 0-1.077 1.043l.258.268c.384.397.699.716.994 1.006-.214.341-.433.545-.622.567-.17.023-.672-.062-1.652-1.24a.75.75 0 0 0-1.153.96c.038.045.345.447.8.857a.705.705 0 0 1-.368.161c-.852.106-2.155-1.178-2.85-1.867l-.207-.203c-.398-.39-1.374-1.384-2.823-2.875a2.997 2.997 0 0 1-.711-2.985l.903-2.891a1.12 1.12 0 0 1 1.073-.79h.238c.765 0 1.514-.144 2.225-.428a4.47 4.47 0 0 1 1.668-.322Zm3.175 0h2.306c.574 0 1.135.108 1.667.322.712.284 1.46.428 2.226.428h.238c.495 0 .926.317 1.073.79l.945 3.024a2.26 2.26 0 0 1-.765 2.446l-1.182.92c-.1-.25-.252-.484-.455-.687l-4.836-4.777a.747.747 0 0 0-.887-.12l-.002-.003c-2.141 1.179-3.358.904-3.681.792-.26-.09-.37-.205-.4-.25 0-.287.024-.34.8-.634.7-.264 1.455-.893 2.375-1.979a.76.76 0 0 1 .578-.272Z" />
		</svg>
	);
};

<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"></svg>;

export default IconHandshake;
