import { SVGProps } from 'react';

export interface IconCommunityProps extends SVGProps<SVGSVGElement> {
	className?: string;
}

const IconCommunity = (props: IconCommunityProps) => {
	const { className, ...remainingProps } = props;

	return (
		<svg
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-label="Community icon"
			className={className}
			{...remainingProps}
		>
			<path d="M5.25 11.25C4.01 11.25 3 10.156 3 8.812 3 7.47 4.01 6.376 5.25 6.376S7.5 7.468 7.5 8.813c0 1.343-1.01 2.437-2.25 2.437ZM8.403 16.125H2.094c-.498 0-1.033-.423-.963-1.055C1.32 13.32 3.09 12 5.25 12c2.157 0 3.927 1.319 4.12 3.068.068.637-.468 1.057-.967 1.057ZM21.903 16.125h-6.309c-.498 0-1.033-.423-.963-1.055.19-1.75 1.96-3.07 4.119-3.07 2.157 0 3.927 1.319 4.12 3.068.068.637-.468 1.057-.967 1.057ZM18.75 11.25c-1.24 0-2.25-1.094-2.25-2.438 0-1.343 1.01-2.437 2.25-2.437S21 7.468 21 8.813c0 1.343-1.01 2.437-2.25 2.437Z" />
			<path d="M16.287 19.875H8.089c-1.188 0-2.107-1.24-1.55-2.395.93-1.931 3.147-3.179 5.649-3.179 2.501 0 4.719 1.248 5.649 3.179.583 1.212-.423 2.395-1.55 2.395Zm-4.1-4.074c-1.899 0-3.634.933-4.298 2.33-.06.126.082.244.2.244h8.197c.117 0 .26-.117.2-.244-.664-1.397-2.4-2.33-4.299-2.33ZM12 13.5c-1.654 0-3-1.43-3-3.188 0-1.757 1.346-3.187 3-3.187s3 1.43 3 3.188c0 1.757-1.346 3.187-3 3.187Zm0-4.875c-.827 0-1.5.757-1.5 1.688 0 .93.673 1.687 1.5 1.687s1.5-.757 1.5-1.688c0-.93-.673-1.687-1.5-1.687Z" />
		</svg>
	);
};

export default IconCommunity;
