import { SVGProps } from 'react';

export interface IconImageFileProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconImageFile = (props: IconImageFileProps) => {
	const { testId = 'IconImageFile', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			className={className}
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...remainingProps}
		>
			<path
				d="M8.75 4.375C7.30013 4.375 6.125 5.55013 6.125 7V21C6.125 22.4499 7.30013 23.625 8.75 23.625H19.25C20.6999 23.625 21.875 22.4499 21.875 21V12.5371C21.875 11.8406 21.5981 11.1733 21.106 10.6812L15.5688 5.14404C15.0762 4.65186 14.409 4.375 13.7129 4.375H8.75ZM8.75 6.125H13.125V10.5C13.125 11.9499 14.3001 13.125 15.75 13.125H20.125V19.9797L17.2325 17.3795C16.7876 16.9792 16.1129 16.98 15.6688 17.3812L12.7405 20.0267L11.2622 18.7621C10.8238 18.3871 10.1774 18.3884 9.74036 18.7646L7.875 20.3711V7C7.875 6.51656 8.26656 6.125 8.75 6.125ZM14.875 6.9248L19.3252 11.375H15.75C15.2666 11.375 14.875 10.9834 14.875 10.5V6.9248ZM10.9375 14C10.2126 14 9.625 14.5876 9.625 15.3125C9.625 16.0374 10.2126 16.625 10.9375 16.625C11.6624 16.625 12.25 16.0374 12.25 15.3125C12.25 14.5876 11.6624 14 10.9375 14Z"
				fill="black"
			/>
		</svg>
	);
};

export default IconImageFile;
