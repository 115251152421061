import { SVGProps } from 'react';

export interface IconPdfFileProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconPdfFile = (props: IconPdfFileProps) => {
	const { testId = 'IconPdfFile', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			className={className}
			viewBox="0 0 29 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-label="PDF File Icon"
			{...remainingProps}
		>
			<path
				d="M9.25 4.375C7.80013 4.375 6.625 5.55013 6.625 7V21C6.625 22.4499 7.80013 23.625 9.25 23.625H19.75C21.1999 23.625 22.375 22.4499 22.375 21V12.5371C22.375 11.8406 22.0981 11.1733 21.606 10.6812L16.0688 5.14404C15.5762 4.65186 14.909 4.375 14.2129 4.375H9.25ZM9.25 6.125H13.625V10.5C13.625 11.9499 14.8001 13.125 16.25 13.125H20.625V21C20.625 21.4834 20.2334 21.875 19.75 21.875H9.25C8.76656 21.875 8.375 21.4834 8.375 21V7C8.375 6.51656 8.76656 6.125 9.25 6.125ZM15.375 6.9248L19.8252 11.375H16.25C15.7666 11.375 15.375 10.9834 15.375 10.5V6.9248ZM10.5232 16.1866C10.2734 16.1866 10.1429 16.3488 10.1429 16.6105V19.3013C10.1429 19.5511 10.2736 19.7131 10.5121 19.7131C10.7505 19.7131 10.8855 19.555 10.8855 19.3013V18.598H11.5734C12.3386 18.598 12.8466 18.1216 12.8466 17.3949C12.8466 16.6717 12.3388 16.1866 11.5811 16.1866H10.5232ZM13.5677 16.1866C13.3179 16.1866 13.1875 16.3488 13.1875 16.6105V19.244C13.1875 19.5056 13.3179 19.667 13.5677 19.667H14.541C15.6212 19.667 16.2286 19.0439 16.2286 17.9213C16.2286 16.8061 15.6256 16.1866 14.541 16.1866H13.5677ZM17.0541 16.1866C16.8003 16.1866 16.6687 16.3488 16.6687 16.6105V19.3013C16.6687 19.5511 16.8003 19.7131 17.0387 19.7131C17.2811 19.7131 17.4117 19.555 17.4113 19.3013V18.2332H18.7032C18.8761 18.2332 18.9912 18.1333 18.9912 17.964C18.9912 17.7872 18.8765 17.6871 18.7032 17.6871H17.4113V16.7754H18.8229C19.0075 16.7754 19.1296 16.6638 19.1296 16.4832C19.1296 16.2985 19.0075 16.1866 18.8229 16.1866H17.0541ZM10.8855 16.7446H11.47C11.8817 16.7446 12.1271 16.9871 12.1271 17.3983C12.1271 17.8021 11.8812 18.0443 11.47 18.0443H10.8855V16.7446ZM13.9027 16.7557H14.441C15.1179 16.7557 15.4827 17.1559 15.4827 17.929C15.4827 18.7134 15.1218 19.1013 14.441 19.1013H13.9027V16.7557Z"
				fill="black"
			/>
		</svg>
	);
};

export default IconPdfFile;
