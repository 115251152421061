export interface IconHumidityProps {
	testId?: string;
	className?: string;
}

const IconHumidity = (props: IconHumidityProps) => {
	const { testId = 'IconHumidity', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 32 32"
			aria-label="Humidity Icon"
			{...remainingProps}
		>
			<path d="M26.095 14.68c.4.59 3.905 5.855 3.905 8.585A4.742 4.742 0 0 1 25.265 28a4.742 4.742 0 0 1-4.735-4.735c0-2.73 3.505-7.995 3.905-8.585a1.005 1.005 0 0 1 1.66 0ZM25.265 26A2.736 2.736 0 0 0 28 23.265c0-1.3-1.45-4.085-2.735-6.175-1.285 2.09-2.735 4.875-2.735 6.175A2.736 2.736 0 0 0 25.265 26ZM19.517 13.831c-1.79 2.494-2.206 2.98-2.456 3.23a1.5 1.5 0 1 1-2.122-2.122c.25-.25.736-.667 3.23-2.457 3.06-2.195 3.284-2.342 3.488-2.139.203.203.056.428-2.14 3.488ZM12.404 20.596a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM10.5 16a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM11.404 12.404a1 1 0 1 1 0-2 1 1 0 0 1 0 2ZM17 9.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
			<path d="M16 5c5.09 0 9.37 3.455 10.625 8.145a2.44 2.44 0 0 0-2.18-.26 9 9 0 1 0-5.305 11.55c.13.67.365 1.305.69 1.88A10.95 10.95 0 0 1 16 27C9.925 27 5 22.075 5 16S9.925 5 16 5Z" />
		</svg>
	);
};

export default IconHumidity;
