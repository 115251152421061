import { SVGProps } from 'react';

export interface IconGreenCheckProps extends SVGProps<SVGSVGElement> {
	className?: string;
}

const IconGreenCheck = (props: IconGreenCheckProps) => {
	const { className, ...remainingProps } = props;

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 37 36"
			aria-label="green circle with checkmark"
			className={className}
			{...remainingProps}
		>
			<path
				fill="#84CC16"
				d="M18.5 0c9.925 0 18 8.075 18 18s-8.075 18-18 18-18-8.075-18-18 8.075-18 18-18Zm8.478 15.446a1.636 1.636 0 1 0-2.314-2.314l-7.463 7.463-4.175-4.176a1.636 1.636 0 1 0-2.313 2.314l5.332 5.332a1.635 1.635 0 0 0 2.313.001l8.62-8.62Z"
			/>
		</svg>
	);
};

export default IconGreenCheck;
