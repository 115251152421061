import { SVGProps } from 'react';

export interface IconLicenseProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconLicense = (props: IconLicenseProps) => {
	const { testId = 'IconLicense', ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 20 20"
			role="img"
			aria-label="License"
			{...remainingProps}
		>
			<path
				d="M10.44 1.67c3.33 0 5 0 6.03.97 1.03.98 1.03 2.55 1.03 5.7v3.33c0 3.14 0 4.71-1.03 5.69-1.04.97-2.7.97-6.03.97h-.88c-3.33 0-5 0-6.03-.97-1.03-.98-1.03-2.55-1.03-5.7V8.34c0-3.14 0-4.71 1.03-5.69 1.04-.97 2.7-.97 6.03-.97h.88Z"
				className="fill-black"
			/>
			<path
				d="M10.44 1.67c3.33 0 5 0 6.03.97 1.03.98 1.03 2.55 1.03 5.7v3.33c0 3.14 0 4.71-1.03 5.69-1.04.97-2.7.97-6.03.97h-.88c-3.33 0-5 0-6.03-.97-1.03-.98-1.03-2.55-1.03-5.7V8.34c0-3.14 0-4.71 1.03-5.69 1.04-.97 2.7-.97 6.03-.97h.88Z"
				className="stroke-black"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M6.67 5.83h6.66M6.67 10h6.66M6.67 14.17H10"
				className="stroke-white"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default IconLicense;
