import { SVGProps } from 'react';

export interface IconFileProps extends SVGProps<SVGSVGElement> {
	className?: string;
}

const IconFile = (props: IconFileProps) => {
	const { className, ...remainingProps } = props;

	return (
		<svg
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-label="paper file icon"
			className={className}
			role="graphics-symbol"
			{...remainingProps}
		>
			<g>
				<path
					id="Vector"
					d="M12.5 6.25C10.4288 6.25 8.75 7.92875 8.75 10V30C8.75 32.0712 10.4288 33.75 12.5 33.75H27.5C29.5712 33.75 31.25 32.0712 31.25 30V17.9102C31.25 16.9152 30.8545 15.9619 30.1514 15.2588L22.2412 7.34863C21.5375 6.64551 20.5842 6.25 19.5898 6.25H12.5ZM12.5 8.75H18.75V15C18.75 17.0712 20.4288 18.75 22.5 18.75H28.75V28.5425L24.6179 24.8279C23.9823 24.256 23.0184 24.2572 22.384 24.8303L18.2007 28.6096L16.0889 26.803C15.4626 26.2674 14.5392 26.2691 13.9148 26.8066L11.25 29.1016V10C11.25 9.30938 11.8094 8.75 12.5 8.75ZM21.25 9.89258L27.6074 16.25H22.5C21.8094 16.25 21.25 15.6906 21.25 15V9.89258ZM15.625 20C14.5894 20 13.75 20.8394 13.75 21.875C13.75 22.9106 14.5894 23.75 15.625 23.75C16.6606 23.75 17.5 22.9106 17.5 21.875C17.5 20.8394 16.6606 20 15.625 20Z"
					fill="black"
				/>
			</g>
		</svg>
	);
};

export default IconFile;
