import { SVGProps } from 'react';
import cn from 'classnames';

export interface IconChevronRightProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconChevronRight = (props: IconChevronRightProps) => {
	const { testId = 'IconChevronRight', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			viewBox="0 0 14 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={cn('h-6 w-full', className)}
			role="img"
			aria-label="chevron pointing right"
			{...remainingProps}
		>
			<path d="M1.677 23.802a.999.999 0 0 1-.699-1.716L11.326 11.99 1 1.913A1 1 0 1 1 2.397.481l11.06 10.792a1 1 0 0 1 0 1.432L2.375 23.518a.998.998 0 0 1-.698.284Z" />
		</svg>
	);
};

export default IconChevronRight;
