import { SVGProps } from 'react';

export interface IconPencilProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconPencil = (props: IconPencilProps) => {
	const { testId = 'IconPencil', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			className={className}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-label="Pencil Icon"
			{...remainingProps}
		>
			<path
				d="M17.1847 4.42896C16.5739 4.42896 15.9636 4.66122 15.4986 5.12622L5.066 15.5588L3.78353 19.3872C3.61478 19.8972 4.10263 20.3851 4.61263 20.2163L8.441 18.9338L18.8736 8.50122C19.8036 7.57122 19.8036 6.05997 18.8736 5.12622C18.4067 4.66122 17.7954 4.42896 17.1847 4.42896ZM17.1224 5.99194C17.3491 5.99199 17.5757 6.07835 17.7486 6.25122C18.0944 6.59697 18.0944 7.15754 17.7486 7.50366L17.2352 8.01782L15.982 6.76465L16.4962 6.25122C16.6691 6.07816 16.8957 5.9919 17.1224 5.99194ZM14.9207 7.82593L16.1739 9.0791L8.75228 16.5037L7.49618 15.2476L14.9207 7.82593ZM6.4349 16.3088L7.691 17.5649V17.5657L6.17635 18.2893C6.04623 18.3516 5.8906 18.3264 5.78597 18.2271C5.67647 18.1232 5.64601 17.9607 5.71126 17.8242L6.4349 16.3088Z"
				fill="#475569"
			/>
		</svg>
	);
};

export default IconPencil;
