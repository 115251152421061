import { SVGProps } from 'react';

export interface IconRefreshArrowsProps extends SVGProps<SVGSVGElement> {
	className?: string;
	testId?: string;
}

const IconRefreshArrows = (props: IconRefreshArrowsProps) => {
	const { testId = 'IconRefreshArrows', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 40 40"
			fill="none"
			aria-label="Circular Arrows Icon"
			{...remainingProps}
		>
			<path
				d="M20 6.25C12.4181 6.25 6.25 12.4181 6.25 20C6.25 20.6888 6.30071 21.3806 6.40259 22.0581C6.49571 22.6781 7.02919 23.1226 7.63794 23.1226C7.69919 23.1226 7.76221 23.1191 7.82471 23.1091C8.50721 23.0073 8.97701 22.3695 8.87451 21.687C8.79139 21.132 8.75 20.5644 8.75 20C8.75 13.7969 13.7969 8.75 20 8.75C22.5897 8.75 25.0804 9.64569 27.0679 11.25H25.625C24.935 11.25 24.375 11.81 24.375 12.5C24.375 13.19 24.935 13.75 25.625 13.75H30C30.69 13.75 31.25 13.19 31.25 12.5V8.125C31.25 7.435 30.69 6.875 30 6.875C29.31 6.875 28.75 7.435 28.75 8.125V9.39453C26.3032 7.37684 23.2132 6.25 20 6.25ZM32.428 16.8787C32.3451 16.8745 32.2606 16.8777 32.1753 16.8909C31.4928 16.9927 31.023 17.6305 31.1255 18.313C31.2086 18.868 31.25 19.4356 31.25 20C31.25 26.2031 26.2031 31.25 20 31.25C17.412 31.25 14.9213 30.3544 12.9333 28.75H14.375C15.065 28.75 15.625 28.19 15.625 27.5C15.625 26.81 15.065 26.25 14.375 26.25H10C9.31 26.25 8.75 26.81 8.75 27.5V31.875C8.75 32.565 9.31 33.125 10 33.125C10.69 33.125 11.25 32.565 11.25 31.875V30.6042C13.6971 32.6222 16.7877 33.75 20 33.75C27.5819 33.75 33.75 27.5819 33.75 20C33.75 19.3112 33.6993 18.6188 33.5974 17.9419C33.5077 17.3436 33.0084 16.9081 32.428 16.8787Z"
				fill="black"
			/>
		</svg>
	);
};

export default IconRefreshArrows;
