import { SVGProps } from 'react';

export interface IconDownloadFileProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconDownloadFile = (props: IconDownloadFileProps) => {
	const { testId = 'IconDownloadFile', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
			aria-label="Download File Icon"
			{...remainingProps}
		>
			<path d="M12 3C11.586 3 11.25 3.336 11.25 3.75V14.3269L8.78174 11.8462C8.48961 11.5526 8.01482 11.5504 7.72119 11.8433C7.42794 12.1354 7.42614 12.6102 7.71826 12.9038L11.4683 16.6729C11.6093 16.8142 11.8005 16.894 12 16.894C12.1995 16.894 12.3907 16.8146 12.5317 16.6729L16.2817 12.9038C16.5739 12.6102 16.5721 12.1354 16.2788 11.8433C15.9852 11.5508 15.5104 11.5529 15.2183 11.8462L12.75 14.3269V3.75C12.75 3.336 12.414 3 12 3ZM3.375 14.25C2.961 14.25 2.625 14.586 2.625 15V18C2.625 19.2409 3.63412 20.25 4.875 20.25H19.125C20.3659 20.25 21.375 19.2409 21.375 18V15C21.375 14.586 21.039 14.25 20.625 14.25C20.211 14.25 19.875 14.586 19.875 15V18C19.875 18.4136 19.5386 18.75 19.125 18.75H4.875C4.46137 18.75 4.125 18.4136 4.125 18V15C4.125 14.586 3.789 14.25 3.375 14.25Z" />
		</svg>
	);
};

export default IconDownloadFile;
